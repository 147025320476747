<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <button class="btn btn-outline-secondary" @click.prevent="search">
        <span class="icons icon-search" />
      </button>
    </div>

    <input
      type="text"
      class="form-control"
      placeholder="חיפוש באתר"
      v-model="sidebarSearchField"
      @keyup.enter="search"
      @input="debounceInput"
    />
  </div>
</template>

<script>
import { eventEmitter } from '@/main'
import _ from 'lodash'

export default {
  name: 'SidebarSearch',

  data() {
    return {
      sidebarSearchField: ''
    }
  },

  methods: {
    search() {
      if(this.sidebarSearchField !== '' && this.$route.name !== 'search') {
        this.$store.commit('setSearchField', this.sidebarSearchField)
        this.$router.push({name: 'search'})
      }

      eventEmitter.$emit('closeSidebarMenu')
    },

    debounceInput: _.debounce(function () {
      this.$store.commit('setSearchField', this.sidebarSearchField)

      if (this.searchField !== '') {
        this.$store.commit('setFilteredProducts', [])
        this.$store.commit('setSearchProducts', [])
      }
    }, 500)
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

#app .sidebar-header .btn {
  padding: 0;
}

@include media-breakpoint-down(sm) {
  .sidebar-header .input-group{
    padding: 0 1rem .5rem;
    border-bottom: 1px solid #d3d3d3;
  }
  .sidebar-header .input-group input{
    background-color: #F4F4F4;
    direction: rtl;
    text-align: right;
    font-size: .8rem;
    border-color: #AAAAAA;
    border-left: none;
    height: 2.35rem;
  }
  #app .sidebar-header .input-group .btn{
    background-color: #F4F4F4;
    border: 1px solid #AAAAAA;
    border-right: none;
    padding-left: .8rem;
    padding-top: .2rem;
  }
}
</style>
