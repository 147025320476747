<template>
  <div :class="[cssClass, {'active': active}]" @click.prevent="toggle" style="cursor:pointer" />
</template>

<script>
export default {
  name: 'FavoriteImageComponent',

  props: ['cssClass', 'active', 'toggle']
}
</script>
