<template>
  <div
    class="card card-product"
    @click.prevent="setScrollPosition"
    :id="'product-' + product.id"
  >
    <div class="card-media" @click="selectProduct(product)">
      <FavoriteImageComponent
        :cssClass="'product-favorite'"
        :active="product.favorite"
        :toggle="toggleFavorite"
      />

      <router-link class="cart-link" :to="getRoute()">
        <!-- Double PIC -->
        <div
          v-if="product.visual_block == 'double_pic'"
          class="d-flex double_container"
        >
          <div class="col p-0" style="padding-left: 1.35rem !important">
            <img
              :src="product.defaultProd.mainImage"
              class="card-img-top"
              :alt="product.titleHEB"
            />
          </div>
          <div class="col p-0">
            <img
              :src="product.defaultProd.images.at(-1)"
              class="card-img-top"
              :alt="product.titleHEB"
            />
          </div>
        </div>
        <!-- / Double PIC -->

        <!-- pic_and_vid -->

        <div
          v-else-if="product.visual_block == 'pic_and_vid'"
          class="d-flex double_container"
        >
          <div class="col p-0 product-video-cont">
            <iframe
              v-if="product.defaultProd.video_link"
              autoplay
              style=""
              :src="`https://player.vimeo.com/video/${product.defaultProd.video_link}?autoplay=1&loop=1&title=0&byline=0&portrait=0`"
              class="product-video"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen=""
              title=""
              data-ready="true"
            >
            </iframe>
          </div>
          <div class="col p-0">
            <img
              :src="product.defaultProd.mainImage"
              class="card-img-top"
              :alt="product.titleHEB"
            />
          </div>
        </div>

        <!-- /Video AND  PIC -->

        <!-- Normal PIC -->
        <div v-else>
          <img
            :src="product.defaultProd.mainImage"
            class="card-img-top"
            :alt="product.titleHEB"
          />
        </div>
        <!-- /Normal PIC -->
      </router-link>

      <img
        :src="getImageSoldOout()"
        alt="out of stock"
        ref="firstSlide"
        class="img-fluid soldout"
        v-if="product.outOfStock"
      />
      <div class="card-media-icons card-sale" v-if="product.sale" />
      <div class="card-media-icons card-sold-out" v-if="product.soldOut" />

      <QuickViewModal
        v-if="info && $route.name !== 'favorites'"
        :product="product"
        :toggle="toggleFavorite"
      />
    </div>

    <div class="card-body" v-if="info">
      <h5 class="card-title">
        <router-link :to="getRoute()" v-text="product.titleHEB" />
      </h5>

      <div class="card-prices">
        <span class="card-price-old" v-if="product.storePriceHEB">
          {{ product.storePriceHEB }}
          <span class="shekel" v-text="'₪'" />
        </span>

        <span class="card-price">
          {{ product.webPriceHEB }}
          <span class="shekel" v-text="'₪'" />
        </span>
      </div>

      <div class="gong-variants-colors" v-if="product.colors" ref="filterColor">
        <button
          v-for="(color, index) of product.colors"
          :key="index"
          :data-id="color.id"
          :class="[
            'gong-color',
            { selectedColor: product.defaultProd.colorID.id === color.id },
          ]"
        >
          <span
            @click="selectColor(color, $event)"
            :style="getStyleColor(color)"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import QuickViewModal from "@/components/QuickViewModal";
import FavoriteImageComponent from "@/components/favorite/FavoriteImageComponent";
import mainMixin from "@/mixins/main.mixin";

export default {
  name: "CardProduct",
  mixins: [mainMixin],
  props: ["product", "info", "wishlist", "loadProd"],

  components: {
    QuickViewModal,
    FavoriteImageComponent,
  },

  methods: {
    getStyleColor(color) {
      return `background-color: #${color.color}; border-color: #${
        color.color === "ffffff" ? "d3d3d3" : color.color
      }`;
    },

    getRoute() {
      if (this.product.valid === 0 || this.product.visible === 0) {
        return {};
      }

      return {
        name: "product",
        params: {
          subCategoryId: this.$route.params.subCategoryId || 0,
          productId: this.product.id,
        },
      };
    },

    selectColor(color, event) {
      console.log(this.product);
      this.$refs.filterColor.childNodes.forEach((child) =>
        child.classList.remove("selectedColor")
      );
      event.target.parentNode.classList += " selectedColor";

      this.product.defaultProd = this.product.products.find(
        (p) => p.colorID.id === color.id
      );
      console.log(this.product.defaultProd);
      this.product.defaultProd.mainImage = this.product.defaultProd.images[0];

      this.$store.commit("setSelectedColorId", color.id);
    },

    selectProduct(product) {
      if (!this.info) {
        this.$store.commit(
          "setSelectedColorId",
          product.defaultProd.colorID.id
        );
      }
    },

    async toggleFavorite() {
      if (this.$store.getters.isLogin) {
        await this.$store.dispatch("updateFavProduct", {
          productId: this.product.id,
        });
        if (this.wishlist === true && this.product.favorite === true) {
          this.product.favorite = !this.product.favorite;
          await this.loadProd();
        } else {
          this.product.favorite = !this.product.favorite;
        }
      } else {
        this.$router.push({
          name: "login",
          params: {
            favorite: {
              subCategoryId: this.$route.params.subCategoryId || 0,
              productId: this.product.id,
            },
          },
        });
      }
    },

    setScrollPosition(event) {
      this.$store.commit("setScrollPosition", {
        x: 0,
        y: event.pageY - event.y,
      });
    },
  },

  created() {
    this.product.defaultProd = this.product.products[0];
    this.product.defaultProd.mainImage = this.product.defaultProd.images[0];
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.soldout {
  width: 50% !important;
  height: auto !important;
  position: absolute;
  right: 0;
  top: 0;
}
.card-product {
  margin-bottom: 0.9rem;
}
.card-media {
  position: relative;
}
.card-media-icons {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  width: 5.5rem;
  height: 5.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.card-sale {
  background-image: url("../assets/images/sale.png");
}
.card-sold-out {
  background-image: url("../assets/images/sold-out.png");
}
.cart-link {
  display: block;
}
#hooperSliderProducts.hooperInnerPage .cart-link {
  max-height: 29rem;
}
#hooperSliderProducts.hooperInnerPage .cart-link img {
  height: 100%;
}
.gong-color {
  width: 13px;
  height: 13px;
}
.gong-color span {
  width: 11px;
  height: 11px;
}
.gong-color.selectedColor {
  width: 17px;
  height: 17px;
  border-color: #8d8d8d;
  margin: 0 0.15rem;
}
.gong-variants-colors {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  min-height: 17px;
}
#product .gong-variants-colors {
  flex-direction: row-reverse;
}
.product-video-cont {
  overflow: hidden;
  z-index: 0;
  position: relative;
  padding-left: 1.35rem !important;
}
.product-video {
  width: 100%;
  height: 116%;
  position: absolute;
  top: -60px;
  right: 0;
  z-index: 1;
}
.double_container {
  margin: 0;
}
@include media-breakpoint-down(sm) {
  .double_container {
    padding: 0;
  }
  .product-video {
    width: 100%;
    height: 116%;
    position: absolute;
    top: -20px;
    left: 0;
  }
  .product-favorite {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    left: 0;
    display: block;
    z-index: 20;
  }
  #app .card-title {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
  .card-media-icons {
    width: 2.75rem;
    height: 2.75rem;
    bottom: 0.4rem;
    left: 0.4rem;
  }
  #home .card-media-icons {
    bottom: 1.3rem;
  }
  .slides-mobile .card-media-icons {
    bottom: 1.45rem;
  }
  .card-prices {
    margin-bottom: 0.6rem;
    font-weight: 600;
  }
  .card-product .card-price,
  .card-product .card-price-old {
    font-size: 0.8rem;
  }
  .products-container .col-md-3 {
    margin-bottom: 0;
  }
  #app .card-body {
    padding-top: 0.9rem;
  }
  #hooperSliderProducts.hooperInnerPage .cart-link {
    height: 14.6rem;
  }
}
</style>
