export default {
  computed: {
    isLogin() {
      return this.$store.getters.isLogin
    }
  },

  methods: {
    goToCheckout() {
      if(window.ReactNativeWebView) {
        if(this.isLogin){
          this.$router.push({ name: 'checkout' })
        }else{
          window.ReactNativeWebView.postMessage('{"act":"checkout"}')
        }
        return
      } else {
        if (this.$route.name !== 'checkout') {
          this.$router.push({ name: 'checkout' })
        } else {
          return
        }
      }
    }
  }
}
