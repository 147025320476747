<template>
  <div id="gong-slider" :class="{sliderModal: isModal}" v-if="'defaultProd' in product">
    <div class="thumbnails" ref="thumbnails">
      <div class="thumbnail" v-for="(image, index) of product.defaultProd.images" :key="index">
        <img :src="image" alt="" @click="mainImageS = image" class="img-fluid">
      </div>
    </div>

    <div class="slider">
      <div class="slide">
        <FavoriteImageComponent :cssClass="'product-favorite-product'" :active="product.favorite" :toggle="toggle" />
        <!-- <img :src="getImageSoldOout()" alt="Out Of Stock" ref="firstSlide" class="img-fluid soldout" v-if="this.outOfStock" /> -->
       
        <img :src="mainImageS || product.defaultProd.mainImage" alt="" ref="firstSlide" class="img-fluid" >
      </div>

      <div class="slide" v-if="!isModal">
       <iframe v-if="product.defaultProd.mainVideo && this.vimeo" autoplay :src="`https://player.vimeo.com/video/${this.playerOptions.sources[0].src}?autoplay=1&loop=1&title=0&byline=0&portrait=0`" class="product-video" style="width:372px;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="" title="Nested Sequence 77_2" data-ready="true"></iframe>
        <!-- <video  :key="product.defaultProd.mainVideo" v-if="product.defaultProd.mainVideo" autoplay loop muted playsinline class="product-video" style="width:372px;">
          <source :src="product.defaultProd.mainVideo" type="video/mp4" />
        </video> -->
      <!-- <video ref="videoPlayer" muted loop autoplay preload="auto" class="product-video" style="width:372px;"  /> -->
      <video-player class="product-video"
            style="width:372px;" 
            ref="videoPlayerRef"
            :options="playerOptions"
            @ready="onPlayerReady"
            v-if="product.defaultProd.mainVideo && !this.vimeo"
             />
      </div>
    </div>
  </div>
</template>

<script>
import FavoriteImageComponent from '@/components/favorite/FavoriteImageComponent'
import mainMixin from '@/mixins/main.mixin';
// import dashjs from 'dashjs'
import { videoPlayer } from 'vue-video-player'
import 'video.js' // OR: import videojs from 'video.js';
import 'videojs-contrib-hls'
import 'video.js/dist/video-js.css'

export default {
  name: 'SliderProducts',
  mixins: [mainMixin],
  props: ['isModal', 'toggle'],

  components: {
    FavoriteImageComponent,
    videoPlayer
  },

  data() {
    return {
      playerOptions: {
        name: 'player',
        autoplay: true,
        loop:true,
        muted:true,
        playsinline:true,
        sources: [{
          type: 'application/x-mpegurl',
          src: '',
          withCredentials: false
        }],
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        flash: { hls: {withCredentials: false } },
        html5: { hls: {withCredentials: false } },
        poster: this.thumbnail,
      },
      vimeo: false,
      mainImageS: null,
      outOfStock: true
    }
  },

  mounted() {
    window.vuePlayerModal = this;
  },
  computed: {
    player() {
      return this.$refs.videoPlayerRef.player;
    },
    product() {
      return this.$store.getters.product
    },
    selectedColorId() {
      return this.$store.getters.selectedColorId
    },
  },
  created() {
    if(this.product.defaultProd.mainVideo && this.product.defaultProd.mainVideo.search('mp4') > 0){
      this.playerOptions.sources = [{
        type: 'application/x-mpegurl',
        src: `https://video.gong-online.co.il/hls/${this.product.defaultProd.mainVideo}/index.m3u8`
      }]
      this.vimeo = false;
    }else if(this.product.defaultProd.mainVideo){
      this.vimeo = true;
      this.playerOptions.sources = [{
        type: 'application/x-mpegurl',
        src: this.product.defaultProd.mainVideo
      }]
    }
    
    this.handleOutOfStock();
  },
  watch: {
    product(newProd) {
      if(newProd.defaultProd)
        this.mainImageS = newProd.defaultProd.mainImage
    },
    selectedColorId() {
      if(this.product.defaultProd.mainVideo && this.product.defaultProd.mainVideo.search('mp4') > 0){
        this.playerOptions.sources = [{
          type: 'application/x-mpegurl',
          src: `https://video.gong-online.co.il/hls/${this.product.defaultProd.mainVideo}/index.m3u8`
        }]
        this.vimeo = false;
      }else if(this.product.defaultProd.mainVideo){
        this.vimeo = true;
        this.playerOptions.sources = [{
          type: 'application/x-mpegurl',
          src: this.product.defaultProd.mainVideo
        }]
      }
      
      // this.playerOptions.sources = [{
      //   type: 'application/x-mpegurl',
      //   src: `https://video.gong-online.co.il/hls/${this.product.defaultProd.mainVideo}/index.m3u8`,
      // }]
      if(this.product.defaultProd)
        this.mainImageS = this.product.defaultProd.mainImage
        this.handleOutOfStock();
    },
  },
  methods: {
    onPlayerReady(player) {
    },
    handleOutOfStock(){
      this.outOfStock = true;
      this.product.sizes.forEach(prod => {
        if(prod.amount > 0 ){
          this.outOfStock = false;
        }
      });
    }
  },
 
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

#gong-slider{
  position: relative;
  display: flex;
  margin-top: -4.7rem;
  height: 30.6rem;
  margin-bottom: 11rem;
  overflow: hidden;
}
.slide{
  position: relative;
}
.soldout{
  width: 50% !important;
  height: auto !important;
  position: absolute;
  right: 0;
}
.thumbnails{
  flex: 0 0 10.8%;
  margin-right: 0.6rem;
  transition: .4s;
}
.sliderModal .thumbnails{
  flex: 0 0 20%;
}
.thumbnail{
  margin-bottom: .28rem;
}
.thumbnail img{
  width: 100%;
  cursor: pointer;
}
.sliderModal .thumbnail img{
  height: 9.1rem;
}
.slider{
  display: flex;
  flex: 0 0 89%;
  align-items: stretch;
}
.quick-view-modal .slider{
  flex: 0 0 79%;
}
.slider .slide {
  flex: 1;
}
.slider .slide:first-child{
  margin-right: 0.25rem;
}
.slider .slide:last-child{
  margin-left: 0.25rem;
}
.sliderModal .slider .slide img{
  width: 100%;
}
#gong-slider.sliderModal{
  height: 37.6rem;
}
.product-video{
  width: 100%;
  height: 100%;
}
.slider .slide img{
  max-height: 100%;
}

@include media-breakpoint-down(sm) {
  #gong-slider{
    margin-top: 0;
    margin-bottom: 1rem;
    height: 32.4rem;
  }
  .thumbnails{
    flex: 0 0 100%;
    display: flex;
  }
  .thumbnail{
    flex: 0 0 100%;
  }
  .slider{
    display: none;
  }
  .slide:first-child{
    display: none;
  }
}

@include media-breakpoint-down(md) {
  #gong-slider{
    height: 17.5rem;
  }
  .thumbnail{
    margin-bottom: .2rem;
  }
  .slider .slide img{
    width: auto;
    height: 100%;
  }
}

@include media-breakpoint-down(lg) {
  .slider .slide img{
    height: auto !important;
  }
}

@media (min-width: 1600px) {
  #gong-slider {
    height: 41.3rem;
  }
}
@media (max-width: 1600px) {
  .slider .slide img{
    width: auto;
  }
}
@media (min-width: 1900px) {
  #gong-slider {
    height: 41.3rem;
  }
  .slider .slide img{
    width: auto;
    height: 100%;
  }
}
</style>
