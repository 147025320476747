<template>
  <div class="search-field">
    <div class="search-header">
      <button class="btn btn-close" @click.prevent="closeSearch" />
      <input
        type="text"
        placeholder="חיפוש"
        ref="searchField"
        v-model="searchField"
        @keyup.enter="search"
        @input="debounceInput"
      />
    </div>
    <div class="search-body custom-scroll-dark">
      <h4 class="search-title">
        תוצאות חיפוש
      </h4>

      <div class="search-loading" v-if="loading">
        <Loading />
      </div>

      <div class="search-container row" v-else>
        <div
          class="search-product col-2"
          v-for="product of searchProducts"
          :key="product.id"
        >
          <router-link
            class="cart-link"
            :to="{
              name: 'product',
              params: {
                subCategoryId: product.menu.id,
                productId: product.id
              }
            }"
          >
            <img :src="product.defaultProd.mainImage" :alt="product.titleHEB" />
          </router-link>

          <h4 class="search-product-title">
            <router-link
              :to="{
                name: 'product',
                params: {
                  subCategoryId: product.menu.id,
                  productId: product.id
                }
              }"
            >
              {{ product.titleHEB }}
            </router-link>
          </h4>

          <p class="search-product-price">
            <span class="shekel">₪</span>
            {{ product.webPriceHEB }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventEmitter } from '@/main'
import _ from 'lodash'

export default {
  name: 'SearchField',

  data() {
    return {
      searchField: '',
      loading: false
    }
  },

  computed: {
    searchProducts() {
      return this.searchField !== '' ? this.$store.getters.searchProducts : []
    }
  },

  methods: {
    closeSearch() {
      this.searchField = ''
      eventEmitter.$emit('closeSearch')
    },

    search() {
      if (this.searchField !== '' && this.$route.name !== '') {
        this.$store.commit('setSearchField', this.searchField)
        this.$router.push({ name: 'search' })
      }

      eventEmitter.$emit('closeSearch')
    },

    debounceInput: _.debounce(async function() {
      if (this.searchField !== '') {
        this.loading = true
        await this.$store.dispatch('getSearchProducts', this.searchField)
        this.loading = false
      } else {
        this.loading = false
        this.$store.commit('setFilteredProducts', [])
        this.$store.commit('setSearchProducts', [])
      }
    }, 500)
  },

  mounted() {
    this.$refs.searchField.focus()
  }
};
</script>

<style scoped>
.search-field {
  width: 100%;
  max-width: 86.3rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e1e1;
  position: absolute;
  top: 2.5rem;
  z-index: 11;
}
.search-header {
  background-color: #e4e4e4;
  opacity: 0.8;
  padding: 0.3rem 3.6rem 0.9rem 1rem;
  display: flex;
}
.search-header input {
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: right;
  font-size: 4.5rem;
  direction: rtl;
}
#app .search-header .btn-close {
  padding: 0;
}
.search-body {
  position: relative;
  padding: 2rem 1.9rem 0.2rem;
  background-color: rgb(255, 255, 255, 0.8);
  max-height: 40rem;
  overflow-y: scroll;
}
.search-title {
  font-size: 1.1rem;
  text-align: right;
  margin-bottom: 1.3rem;
  position: relative;
  z-index: 12;
}
.search-container {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  z-index: 12;
}
.search-container .search-product:last-child {
  margin-left: 0;
}
.search-product img {
  width: 100%;
  margin-bottom: 1rem;
}
.search-product-title {
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 0.3rem;
}
.search-product-title a {
  color: #000;
}
.search-product-price {
  text-align: center;
}
.search-header .btn-close:after {
  content: "";
  width: 2.9rem;
  height: 2.9rem;
  background: url("../assets/images/close.svg") no-repeat center;
  background-size: contain;
  transform: rotate(0);
}
.search-loading {
  display: flex;
  justify-content: center;
}
.search-loading #loading {
  padding-top: 0;
  padding-bottom: 3rem;
}
</style>
