import api from '@/api'

export default {
  state: {
    status: '',
    loadingRegister: false,
    loadingLogin: false,
    loadingForgot: false,
    disableRegister: false,
    disableLogin: false,
    disableForgot: false,
    codePhone: false,
    errorRegister: '',
    errorLogin: '',
    successForgot: '',
    errorForgetPassword: '',
    errorUpdateProfile: '',
    errorUpdatePassword: '',
    smallRegistered: false,
    smallRegisterError: '',
    user: {},
    token: localStorage.getItem('user-id') || '',
    successUpdatePassword: false,
    isRegistered: false,
    isUpdated: false,

    verifyResetPasswordStatus: null,
    verifyResetPasswordMsg: ''
  },

  mutations: {
    setSmallRegistered(state, value) {
      state.smallRegistered = value
    },
    setSmallRegisterError(state, message) {
      state.smallRegisterError = message
    },
    auth_request_register(state){
      state.loadingRegister = true
      state.disableRegister = true
    },
    auth_request_login(state){
      state.loadingLogin = true
      state.disableLogin = true
    },
    auth_request_forget_password(state){
      state.loadingForgot = true
      state.disableForgot = true
    },
    auth_error_register(state, error){
      state.loadingRegister = false
      state.disableRegister = false
      state.errorRegister = error
    },
    auth_error_login(state, error){
      state.loadingLogin = false
      state.disableLogin = false
      state.errorLogin = error
    },
    auth_error_forget_password(state, error){
      state.loadingForgot = false
      state.disableForgot = false
      state.errorForgetPassword = error
    },
    auth_succes_register(state, user){
      state.loadingRegister = false
      state.disableRegister = false
      state.errorRegister = ''
      state.user = user.user
      state.token = user.user.id
    },
    setIsRegistered(state, isRegistered) {
      state.isRegistered = isRegistered
    },
    setIsUpdated(state, isUpdated) {
      state.isUpdated = isUpdated
    },
    auth_succes_login(state, user){
      state.loadingLogin = false
      state.disableLogin = false
      state.errorLogin = ''
      state.codePhone = true
      state.user = user
      state.token = user.id
    },
    auth_succes_forget_password(state, msg){
      state.loadingForgot = false
      state.disableForgot = false
      state.errorForgetPassword = ''
      state.successForgot = msg
    },
    clearErrorForgetPassword(state){
      state.errorForgetPassword = ''
    },
    clearErrorLogin(state){
      state.errorLogin = ''
    },
    clearErrorUpdatePassword(state){
      state.errorUpdatePassword = ''
    },
    clearSuccessForgetPassword(state){
      state.successForgot = ''
    },
    set_user_success(state, user){
      state.user = user.user
    },
    set_user_error(state){
      state.user = {}
      state.token = ''
    },
    set_logout(state){
      state.user = {}
      state.token = ''
    },
    update_profile_error (state, resp) {
      state.errorUpdateProfile = resp.msg
    },
    update_password_error (state, resp) {
      state.errorUpdatePassword = resp.msg
    },
    update_password_succes (state, status) {
      state.successUpdatePassword = status
    },
    setVerifyResetPassword (state, {status, msg}) {
      state.verifyResetPasswordStatus = status
      state.verifyResetPasswordMsg = msg
    },
    check_code_request(state){
      state.loading = true
      state.disable = true
      state.error = ''
    }
  },

  actions: {
    async registration({ commit }, { form }) {
      try {
        commit('auth_request_register')
        const resp = await api.auth.registration(form)
        if(resp.status) {
          commit('auth_succes_register', resp)
          commit('setIsRegistered', true)
          const id_user = resp.user.id
          localStorage.setItem('user-id', id_user)  
        } else{
          commit('auth_error_register',resp.errors)
        }
      } catch (e) {
        console.log(e, 'registration')
      }
    },


    async login({ commit, dispatch }, { form }) {
      try {
        commit('auth_request_login')
        const resp = await api.auth.login(form)
        if(resp.status){
          commit('auth_succes_login',resp.user) 
          const id_user = resp.user.id
          localStorage.setItem('user-id', id_user) 
          await dispatch('getCart')
        } 
        else{
          commit('auth_error_login',resp.msg) 
        } 
      } catch (e) {
        console.log(e, 'login')
      }
    },

    async logout({ commit }) {
      try {
        const resp = await api.auth.logout()
        if(resp.status) {
          if(window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage('{"act":"logout"}')
          }
          localStorage.removeItem('user-id')
          commit('set_logout')
        }
      } catch (e) {
        console.log(e, 'logout')
      }
    },

    async sendNewPassword({ commit }, { form }) {
      try {
        commit('auth_request_forget_password')

        const resp = await api.auth.forgetPassword(form)
        resp.status
          ? commit('auth_succes_forget_password', resp.msg)
          : commit('auth_error_forget_password', resp.msg)

      } catch (e) {
        console.log(e, 'sendNewPassword')
      }
    },

    async smallRegistration({ commit }, { form }) {
      try {
        const newUser = await api.auth.smallRegistration(form)
        newUser.status
          ? commit('setSmallRegistered', true)
          : commit('setSmallRegisterError', newUser.msg)

      } catch (e) {
        console.log(e, 'smallRegistration')
      }
    },

    async getUserData({ commit }){
      try {
        const resp = await api.auth.getUser()
        if(resp.status) {
          commit('set_user_success', resp)
        } else{
          localStorage.removeItem('user-id')
          commit('set_user_error')
        }
      } catch (e) {
        console.log(e, 'getUser')
      }
    }, 

    async updateUser({ commit }, { form }) {
      try {
        const resp = await api.auth.update(form)
        resp.status
          ? commit('setIsUpdated', true)
          : commit('update_profile_error', resp)

      } catch (e) {
        console.log(e, 'update_user')
      }
    },

    async updatePasswordUser({ commit }, { form }) {
      try{
        const resp = await api.auth.updatePass(form)
        resp.status
          ? commit('update_password_succes', true)
          : commit('update_password_error', resp)

      } catch (e) {
        console.log(e, 'update_user_paswword')
      }
    },

    async verifyResetPassword({ commit }, { form }) {
      try {
        const response = await api.auth.verifyResetPassword(form)
        commit('setVerifyResetPassword', response)
      } catch (e) {
        console.error(e, 'verifyResetPassword')
      }
    },

    async checkExists({ commit }, { form }) {
      try {
        const response = await api.auth.checkExists(form)
        commit('setCheckExists', response.status)
      } catch (e) {
        console.error(e, 'setCheckExists actions')
      }
    },
  },

  getters: {
    user: state => state.user,
    token : state => state.token,
    isLogin: state => !!state.token,
    smallRegistered: state => state.smallRegistered,
    smallRegisterError: state => state.smallRegisterError,
    authLoadingRegister: state => state.loadingRegister,
    buttonDisableRegister: state => state.disableRegister,
    authLoadingLogin: state => state.loadingLogin,
    buttonDisableLogin: state => state.disableLogin,
    authLoadingForgot: state => state.loadingForgot,
    buttonDisableForgot: state => state.disableForgot,
    errorRegister: state => state.errorRegister,
    errorLogin: state => state.errorLogin,
    errorForgot: state => state.errorForgetPassword,
    errorUpdateProfile: state => state.errorUpdateProfile,
    errorUpdatePassword: state => state.errorUpdatePassword,
    successForgot: state => state.successForgot,
    successUpdatePassword: state => state.successUpdatePassword,
    isRegistered: state => state.isRegistered,
    isUpdated: state => state.isUpdated,

    verifyResetPasswordStatus: state => state.verifyResetPasswordStatus,
    verifyResetPasswordMsg: state => state.verifyResetPasswordMsg
  }
}
