<template>
  <li class="list-inline-item small-cart-container">
    <a href="#" @click.prevent="open">
      <span class="icons icons-cart">
        <span class="quantityBag" v-text="allQuantity" />
      </span>
      <span class="icon-alt" v-text="'סל קניות'" />
    </a>
    <transition
      name="custom-classes-transition"
      :enter-active-class="animationClassesIn"
      :leave-active-class="animationClassesOut"
    >
      <div class="small-cart" v-show="showSmallCart" @mouseover="overCart = true" @mouseleave="closeCartAfter">
        <div class="small-cart-header">
          <button class="btn btn-close" @click="closeCart" v-text="'סגור'" />
          <h4 class="small-cart-title" v-text="'סל קניות'" />
        </div>
        <div class="small-cart-body custom-scroll-dark">
          <template v-if="cart.products && cart.products.length">
            <CardProductOrder
              v-for="(product, index) of cart.products"
              :key="index"
              :product="product"
              :changeQuantity="true"
              :canDelete="true"
            />
          </template>
          <div class="cart-empty" v-else>
            <p v-text="'Your cart is empty'" />
          </div>
        </div>
        <div class="small-cart-footer">
          <div class="cart-checkout intermediate-summary">
            <p><span class="shekel" v-text="'₪'" /> {{ cart.totalProds || 0 }}</p>
            <p>סיכום ביניים ({{allQuantity}} מוצרים)</p>
          </div>
          <div v-if="delivery" class="cart-checkout intermediate-summary">
            <p><span class="shekel" v-text="'₪'" /> {{ delivery.price }}</p>
            <p>שליח עד הבית</p>
          </div>
          <div class="cart-checkout intermediate-summary" v-if="cart.voucher && cart.voucher.code && cart.totalVoucher > 0">
            <p><span class="shekel" v-text="'₪'" /> -{{ cart.totalVoucher }}</p>
            <p>הנחת קופון
              (מקנה: {{ cart.voucher.amount}}{{ cart.voucher.type_discount == 'NIS' ? '₪' : "%" }} הנחה)
            </p>
          </div>
          <div class="cart-checkout total-payment">
            <p><span class="shekel" v-text="'₪'" /> {{ cart.total }}</p>
            <p>סה"כ לתשלום</p>
          </div>
          <div class="small-cart-buttons">
            <a v-if="cart.products && cart.products.length" @click.prevent="goToCheckout" href="#" class="btn btn-primary" v-text="'קופה'" />
            <router-link :to="{ name: 'cart' }" class="btn btn-outline-primary" v-text="'סל קניות'" />
          </div>
        </div>
      </div>
    </transition>
  </li>
</template>
<script>
import device from 'current-device'
import { eventEmitter } from '@/main'
import cartMixin from '@/mixins/cart.mixin'
import checkoutMixin from '@/mixins/checkout.mixin'
import CardProductOrder from '@/components/CardProductOrder'

export default {
  name: 'SmallCart',

  components: {
    CardProductOrder
  },

  mixins: [cartMixin, checkoutMixin],

  data() {
    return {
      device,
      showSmallCart: false,
      animationClassesIn: 'animated',
      animationClassesOut: 'animated',
      fixedMainMenu: false,
      overCart: false,
      timeOut: null,
      afterClearTimeout: false
    }
  },

  computed: {
    cart() {
      return this.$store.state.cart.cart
    },
    delivery() {
      return this.$store.state.cart.delivery
    }
  },

  watch: {
    $route() {
      this.showSmallCart = false
    },

    overCart(newVal) {
      if(newVal && this.timeOut !== null) {
        clearTimeout(this.timeOut)
      }
    },
    showSmallCart(newVal){
      eventEmitter.$emit('handleSidebarCart', newVal)
    }
  },

  mounted() {
    if(device.desktop() || device.tablet()) {
      this.animationClassesIn = 'animated bounceInLeft'
      this.animationClassesOut = 'animated bounceOutLeft'
    } else if(device.mobile()) {
      this.animationClassesIn = 'animated bounceInRight'
      this.animationClassesOut = 'animated bounceOutRight'
    }
  },

  created() {
    eventEmitter.$on('fixedMainMenu', value => this.fixedMainMenu = value)

    eventEmitter.$on('openSmallCart', (timeOut = false) => {
      this.showSmallCart = true
      timeOut && (this.timeOut = setTimeout(() => this.showSmallCart = false, 4000))
      this.afterClearTimeout = true
    })
    eventEmitter.$on('closeSmallCart', () => this.showSmallCart = false)
  },

  methods: {
    closeCart() {
      this.showSmallCart = false
    },

    open() {
      if (this.$route.name !== 'payments') {
        this.showSmallCart = !this.showSmallCart
      }
    },

    closeCartAfter() {
      if(this.afterClearTimeout) {
        this.showSmallCart = false
        this.afterClearTimeout = false
        this.overCart = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';
.small-cart{
  position: fixed;
  z-index: 12;
  left: 0;
  top: 7.4rem;
  width: 24.2rem;
}
.smallCartFixedMainMenu{
  top: 3.4rem;
}
.cart-checkout{
  margin-bottom: .5rem;
}
.total-payment{
  margin-bottom: 1.5rem;
}
.cart-checkout p{
  font-size: .8rem;
  margin-bottom: 0;
}
header .small-cart-container{
  margin-right: .6rem;
  padding-top: .15rem;
}
.icons-cart{
  margin-right: 5px;
}
.small-cart-buttons .btn{
  width: 100%;
}
.small-cart-buttons .btn:first-child{
  margin-right: .5rem;
}
.small-cart-buttons .btn:last-child{
  margin-left: .5rem;
}
.good-info-characteristics .gong-spinbutton p {
  margin-left: .5rem;
}
.good-info-characteristics .gong-spinbutton p, .good-info-characteristics .gong-spinbutton .form-control{
  display: inline-block;
}
#app .small-cart-header .btn-close{
  font-family: 'Varela Round', sans-serif;
  font-size: .7rem;
  font-weight: normal;
}
.quantityBag{
  position: absolute;
  width: 1.75rem;
  height: 1.5rem;
  text-align: center;
  font-size: 0.6rem;
  top: 0.7rem;
  color: #FFF;
}
.small-cart-body{
  direction: rtl;
}

@include media-breakpoint-down(sm) {
  .small-cart{
    border-top: none;
    border-left: 1px solid #E1E1E1;
    width: 18.55rem;
    top: 0;
    right: 0;
    left: inherit;
  }
  .small-cart-header{
    padding: .8rem 1.4rem;
  }
  .small-cart-title{
    font-size: .8rem;
  }
  #app .small-cart-header .btn-close{
    font-size: .6rem;
  }
  #app .small-cart-header .btn-close:after{
    font-size: 1.7rem;
  }
  .cart-good{
    padding: .7rem 1rem .2rem;;
  }
  .good-info-title{
    font-size: .8rem;
  }
  .good-info p{
    font-size: .7rem;
  }
  .good-media{
    width: 10.5rem;
    margin-left: .9rem;
  }
  #app .good-media .btn{
    font-size: .7rem;
    font-weight: normal;
  }
  .small-cart-body{
    height: 25rem;
  }
  .cart-checkout{
    margin-bottom: .3rem;
  }
  .cart-checkout p{
    font-size: .7rem;
  }
  .small-cart-buttons{
    margin-top: .8rem;
  }
  #app .small-cart-footer .btn{
    font-size: .7rem;
    font-weight: normal;
    padding: .5rem 1.5rem;
  }
  .small-cart-footer{
    padding: 1rem .9rem 1.6rem 1.3rem;
  }
  header .small-cart-container{
    margin-right: 0;
  }
  .small-cart-container > a{
    text-align: center;
    color: #000;
    display: block;
    line-height: 1;
  }
  .icons-cart{
    width: 1.15rem;
    margin-bottom: .2rem;
    margin-right: 0;
  }
  .icon-alt{
    display: block;
  }
  .quantityBag {
    position: relative;
    width: 1.15rem;
    text-align: center;
    font-size: 0.6rem;
    top: 0.35rem;
    color: #FFF;
  }
}

@media (max-height: 768px) {
  .small-cart{
    top: 0;
  }
}
</style>
