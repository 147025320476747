import api from '@/api'

export default {
  state: {
    seoHome: {},
    video: {},
    banners: {},
    sliderProducts: [],
  },

  mutations: {
    setInfo(state, {video, banners, sliderProducts, seo}) {
      state.seoHome = seo
      state.video = video
      state.banners = banners
      state.sliderProducts = sliderProducts
    },
  },

  actions: {
    async getDataForHomePage({dispatch, commit}) {
      try {
        let response = await api.home.getVideo()

        response.banners.center = response.banners.center.map(banner => ({
          ...banner,
          file: process.env.VUE_APP_IMAGE_URL + '/' + banner.file,
          mobile_file: banner.mobile_file && process.env.VUE_APP_IMAGE_URL + '/' + banner.mobile_file
        }))

        response.banners.left = response.banners.left.map(banner => {
          if(device.desktop() && banner.file){
            if(banner.file.search('files/') >= 0){
              return {
                url: banner.url,
                type: 1,
                src:  process.env.VUE_APP_IMAGE_URL+ '/' + banner.file,
                typeTitle: 'img'
              }
            }else if(banner.file.search('.mp4') >= 0){
              return {
                url: banner.url,
                type: 2,
                src:  `https://video.gong-online.co.il/hls/${banner.file}/index.m3u8`,
                typeTitle: 'mp4'
              }
            }else{
              return {
                url: banner.url,
                type: 3,
                src:  banner.file,
                typeTitle: 'vimeo'
              }
            }

          }else{

            if(banner.mobile_file.search('files/') >= 0){
              return {
                url: banner.url,
                type: 1,
                src:  process.env.VUE_APP_IMAGE_URL+ '/' + banner.mobile_file,
                typeTitle: 'img'
              }
            }else if(banner.mobile_file.search('.mp4') >= 0){
              return {
                url: banner.url,
                type: 2,
                src:  `https://video.gong-online.co.il/hls/${banner.mobile_file}/index.m3u8`,
                typeTitle: 'mp4'
              }
            }else{
              return {
                url: banner.url,
                type: 3,
                src:  banner.mobile_file,
                typeTitle: 'vimeo'
              }
            }

          }
        })
        response.banners.right = response.banners.right.map(banner => {
          if(device.desktop() && banner.file){
            if(banner.file.search('files/') >= 0){
              return {
                url: banner.url,
                type: 1,
                src:  process.env.VUE_APP_IMAGE_URL+ '/' + banner.file,
                typeTitle: 'img'
              }
            }else if(banner.file.search('.mp4') >= 0){
              return {
                url: banner.url,
                type: 2,
                src:  `https://video.gong-online.co.il/hls/${banner.file}/index.m3u8`,
                typeTitle: 'mp4'
              }
            }else{
              return {
                url: banner.url,
                type: 3,
                src:  banner.file,
                typeTitle: 'vimeo'
              }
            }

          }else{
            
            if(banner.mobile_file.search('files/') >= 0){
              return {
                url: banner.url,
                type: 1,
                src:  process.env.VUE_APP_IMAGE_URL+ '/' + banner.mobile_file,
                typeTitle: 'img'
              }
            }else if(banner.mobile_file.search('.mp4') >= 0){
              return {
                url: banner.url,
                type: 2,
                src:  `https://video.gong-online.co.il/hls/${banner.mobile_file}/index.m3u8`,
                typeTitle: 'mp4'
              }
            }else{
              return {
                url: banner.url,
                type: 3,
                src:  banner.mobile_file,
                typeTitle: 'vimeo'
              }
            }

          }
        })

        response.sliderProducts = await dispatch('transform', {productsFetch: response.sliderProducts})

        if(!response.video.video) {
          response.video.desktopBanner = process.env.VUE_APP_IMAGE_URL+'/'+response.video.desktopBanner
          response.video.mobileBanner = process.env.VUE_APP_IMAGE_URL+'/'+response.video.mobileBanner
        }

        commit('setInfo', response)
      } catch (e) {
        console.log(e, 'getVideos')
      }
    },
  },

  getters: {
    seoHome: state => state.seoHome,
    video: state => state.video,
    banners: state => state.banners,
    sliderProducts: state => state.sliderProducts,
  }
}
