import Vue from 'vue'
import VueResource from 'vue-resource'

Vue.use(VueResource)

const api = new Vue({
  https: {
    emulateJSON: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  },
  http: {
    credentials: true,
  },
})

const post = async (urn, body) => {
  return new Promise((resolve, reject) => {
    api.$resource(process.env.VUE_APP_SERVER_URL+'/'+urn)
      .save(body)
      .then(response => response.json())
      .then(data => resolve(data))
      .catch(err => reject(err))
  })
}

const get = urn => {
  return new Promise((resolve, reject) => {
    api.$resource(process.env.VUE_APP_SERVER_URL+'/'+urn)
      .get()
      .then(response => response.json())
      .then(data => resolve(data))
      .catch(err => reject(err))
  })
}

export default {
  auth: {
    login: async body => await post('users/login', body),

    logout: async () => await get('users/logout'),

    registration: async body => await post('users/add', body),

    getUser: async () => await get('users/get'),

    smallRegistration: async body => await post('newsLletter/add', body),

    forgetPassword: async body => await post('users/resetPass', body),

    update: async (body) => await post('users/updatedetailsuser', body),

    updatePass: async (body) => await post('users/updatePass', body),

    verifyResetPassword: async (body) => await post('users/verifyResetPassword', body),

    checkExists: async (body) => await post('users/checkexists', body)
  },

  home: {
    getVideo: async () => await get('indexSettings')
  },

  products: {
    async getByCategory(categoryId, page, colorId) {
      let urn = `categories/${categoryId}/products?page=${page || 1}`
      if(colorId) urn += `&colorID=${colorId}`
      return await get(urn)
    },

    find: async productId => await get(`product/${productId}`),

    completeTheLook: async (productId, colorId) => await get(`product/${productId}/${colorId}/completethelook`),

    searchByTitle: async title => await get(`search/${title}`),

    tableSize: async id => await get(`product/tableSize/${id}`),

    favProducts: async () => await get(`favorites/get`),

    updateFavProduct: async id => await get(`favorites/${id}/put`),

    remidMeLater: async body => await post(`product/remindme`, body)
  },

  categories: {
    all: async () => await get('topMenu'),

    globalSettings: async () => await get('globalSettings')
  },

  colors: {
    get: async subCategoryId => await get(`getAllColors?categories=${subCategoryId}`)
  },

  cart: {
    get: async () => await get('cart/getCart'),

    add: async body => await post('cart/add', body),

    updateDelivery: async body => await post('users/updatedelivery', body),

    delete: async body => await post('cart/removeProd', body),
  },

  footer: {
    get: async () => await get('getFooter'),
  },

  content: {
    get: async id => await get(`content/${id}`),
  },

  checkout: {
    getPlacesCitiesFromGoogle: async search => await get(`searchPlacesCities/${search}`),

    getPlacesAdressFromGoogle: async (search, city) => await get(`searchPlacesStreets/${search}/${city}`),

    details: async () => await get('users/checkoutdetails'),

    updateDetailsUser: async body => await post('users/updatedetailsuser', body),

    saveCheckout: async body => await post('users/savecheckout', body),

    payment: async body => await post('checkout/afterPayment', body),

    paypal: async body => await get('paypal/getBtn'),

    thankYou: async () => await get('users/thankyou'),

    orderForFacebookMethod: async orderId => await get(`orderForGoogleMethod/${orderId}`),
  },

  payment: {
    verify: async body => await post('payplus/verify-new', body),

    failure: async body => await post('payplus/failure', body)
  },

  order:{
    getUserOrders: async () => await get('users/getUserOrders'),
  },

  paypal: {
    create: async body => await post('/paypal/createPayment', body),

    capture: async body => await post('/paypal/capturePayment', body)
  },

  coupons: {
    checkVouchers: async body => await post('vouchers/setVouchers', body),

    cancelVouchers: async body => await post('vouchers/cancelVouchers', body)
  }
}
