<template>
  <li class="list-inline-item toggle-main-menu">
    <a href="#" @click.prevent="sidebarMenuOpen = !sidebarMenuOpen">
      <i class="fas fa-bars" />
      <span class="icon-alt" v-text="'תפריט'" />
    </a>

    <b-sidebar id="sidebar-menu" shadow backdrop no-header v-model="sidebarMenuOpen">
      <template v-slot:default="{ hide }">
        <div class="sidebar-container">
          <div class="sidebar-header">
            <div class="sidebar-title">
              <h4><a href="/" v-html="'GONG'" /></h4>

              <div style="display: flex;">
                <div class="div-fav">
                  <router-link :to="{name: 'favorites'}" class="btn icons icons-favorite" @click="sidebarMenuOpen = false" />
                  <span class="icon-alt" v-text="'Wishlist'" />
                </div>

                <button class="btn btn-close" @click="sidebarMenuOpen = false" style="line-height: unset;">
                  <span class="icon-alt" v-text="'תפריט'" />
                </button>
              </div>
            </div>

            <div class="sidebar-logged" v-if="isLogin">
              <span style="direction:rtl;text-align:right" v-text="users.fname+' שלום'" />
            </div>

            <SidebarSearch />
          </div>

          <div class="sidebar-body">
            <ul class="navbar-nav">
              <li
                :class="['nav-item', {'none-subMenu': !category.subMenu.length}]"
                v-for="(category, index) of categories"
                :key="index"
                v-b-toggle="`accordion-${category.id}`"
                @click="collapseHandle(category, $event)"
              >
                <div class="nav-div">
                  <router-link v-if="!category.subMenu.length" :to="{name: 'subCategories', params: {subCategoryId: category.id, scrollTop: true}}" class="nav-link">
                    {{ category.titleHEB }}
                  </router-link>

                  <a v-else href="#" class="nav-link 2" v-text="category.titleHEB" />

                  <b-collapse :id="`accordion-${category.id}`" accordion="my-accordion" role="tabpanel">
                    <router-link
                      class="nav-link nav-sub-link"
                      v-for="(subMenu, index) of category.subMenu"
                      :key="index"
                      :to="{name: 'subCategories', params: {subCategoryId: subMenu.id, fromMenu: true, scrollTop: true}}"
                      v-text="subMenu.titleHEB"
                    />
                  </b-collapse>
                </div>
              </li>
            </ul>
          </div>

          <div class="sidebar-footer">
            <ul class="list-unstyled">
              <template class="list-unstyled" v-if="!isLogin">
                <li @click="goToAuth(true)">
                  <a href="#" v-text="'כניסת משתמש'" />
                </li>
                <li @click="goToAuth(false)">
                  <a href="#" v-text="'הרשמת משתמש חדש'" />
                </li>
              </template>
              <template v-if="isLogin">
                <li><router-link :to="{name: 'profile'}" v-text="'לאזור האישי שלך'" /></li>
                <li><a href="#" @click="logout()" v-text="'התנתקות'" /></li>
              </template>

              <li><router-link :to="{name: 'content', params: {contentId: 5}}" v-text="'יצירת קשר'" /></li>
              <li><router-link :to="{name: 'content', params: {contentId: 4}}" v-text="'משלוחים והחזרות'" /></li>
              <li><router-link :to="{name: 'content', params: {contentId: 2}}" v-text="'תקנון'" /></li>
            </ul>
          </div>
        </div>
      </template>
    </b-sidebar>
  </li>
</template>

<script>
import { eventEmitter } from '@/main'
import SidebarSearch from '@/components/menu/SidebarSearch'

export default {
  name: 'SidebarMenu',

  components: {
    SidebarSearch
  },

  data() {
    return {
      sidebarMenuOpen: false,
    }
  },

  watch: {
    sidebarMenuOpen(newVal) {
      eventEmitter.$emit('handleSidebarMenu', newVal)
    }
  },

  methods:{
    async logout(){
      await this.$store.dispatch('logout')
      this.sidebarMenuOpen = false
      window.location.href = '/'
    },

    eventPost(){
       this.sidebarMenuOpen = true;
    },

    goToAuth(noRegister) {
      if (window.ReactNativeWebView) {
        if(noRegister){
          window.ReactNativeWebView.postMessage('{"act":"login","url":"http://192.168.1.27:8083/sub-category-55/product-1441"}');
          this.sidebarMenuOpen = false;
          
        }else{
          window.ReactNativeWebView.postMessage('{"act":"register","url":"http://192.168.1.27:8083/sub-category-55/product-1441"}');
          this.sidebarMenuOpen = false;
        }
        return
      } else {
        this.sidebarMenuOpen = false
        eventEmitter.$emit('changeRegForm', noRegister)
        this.$router.push({ name: 'login', params: { noRegister } })
      }
    },

    collapseHandle(category, event) {
      const subCategoryId = this.$route.params.subCategoryId

      if(category.id === subCategoryId) {
        this.sidebarMenuOpen = false
      }

      if(!category.id && !category.subMenu.length) {
        this.sidebarMenuOpen = false
      }

      if(event.path[0].classList.contains('nav-sub-link')) {
        if(category.subMenu.findIndex(sub => sub.id === subCategoryId) !== -1) {
          this.sidebarMenuOpen = false
        }
      }
    }
  },

  computed: {
    categories() {
      return this.$store.getters.categories
    },
    users() {
      return this.$store.getters.user
    },
    isLogin(){
      return this.$store.getters.isLogin
    }
  },
  created() {
    eventEmitter.$on('closeSidebarMenu', () => this.sidebarMenuOpen = false)
    eventEmitter.$on('openSidebarMenu', () => this.sidebarMenuOpen = true)
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

header .top a{
  color: #000;
}
.toggle-main-menu{
  display: none;
}
#app .toggle-main-menu i{
  font-size: 1.3rem;
  margin-bottom: .3rem;
}
#sidebar-menu ul li {
  padding: 1.15rem 1.5rem;
  border-bottom: 1px solid #d3d3d3;
}
#sidebar-menu .sidebar-body ul li:last-child{
  border-bottom-color: #202020;
  border-bottom-width: 2px;
}
#sidebar-menu ul li a{
  font-size: .8rem;
}
#app .sidebar-header .btn {
  padding: 0;
}
.sidebar-header .btn-close:after{
  content: none;
}
.sidebar-header .btn-close:before{
  content: '+';
  display: inline-block;
  vertical-align: middle;
  font-family: 'v';
  font-size: 2.5rem;
  font-weight: 100;
  line-height: 0;
  margin-left: 4px;
  transition: .4s;
  transform: rotate(45deg);
  margin-right: .3rem;
  margin-bottom: .6rem;
}
#sidebar-menu .sidebar-footer ul li:last-child{
  border-bottom: none;
}
.sidebar-title{
  padding: 1rem 1.05rem .7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;
}
.sidebar-title h4 {
  font-size: 2rem;
  margin-bottom: 0;
}
.sidebar-body ul li{
  display: flex;
  justify-content: space-between;
}
.sidebar-body ul li:before{
  content: '';
  width: 1rem;
  height: 1rem;
  background: url('../../assets/images/ArrowLeft.svg') no-repeat center;
  transition: .4s;
}
.sidebar-body ul li.collapsed:before{
  transform: rotate(0deg);
}
.sidebar-body ul li:before{
  transform: rotate(-90deg);
}
.sidebar-body .nav-link {
  padding: 0;
}
.div-fav{
  margin-right: .6rem;
}
.div-fav .icon-alt{
  font-weight: bold;
  color: #212529;
  font-family: 'Roboto Light', sans-serif;
}
.icons-favorite{
  display: inline-block !important;
}

@include media-breakpoint-down(sm) {
  .toggle-main-menu{
    display: block;
  }
  header .top .list-inline-item a{
    display: block;
    line-height: 1.5;
  }
  .toggle-main-menu > a{
    text-align: center;
  }
  .icon-alt{
    display: block;
  }
  .sidebar-title{
    border-bottom: none;
    margin-bottom: .5rem;
  }
  .sidebar-logged span{
    font-size: .8rem;
  }
  .sidebar-logged {
   padding: 0 1.05rem .4rem;
  }
  .nav-div{
    width: 100%;
  }
  .sidebar-body ul li.none-subMenu:before{
    content: none;
  }
}
</style>
