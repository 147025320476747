var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"site-section",attrs:{"id":"banners"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section-body"},[(_vm.banners.center && _vm.banners.center.length)?_c('div',{staticClass:"row"},_vm._l((_vm.banners.center),function(banner,index){return _c('div',{key:index,staticClass:"col-md-12"},[_c('div',{staticClass:"banner-col"},[_c('router-link',{staticClass:"banner-url",attrs:{"to":{name: 'subCategories', params: {subCategoryId: banner.url}}}},[(_vm.device.desktop())?_c('img',{attrs:{"src":banner.file,"alt":banner.titleHEB}}):_vm._e(),(_vm.device.mobile())?_c('img',{attrs:{"src":banner.mobile_file,"alt":banner.titleHEB}}):_vm._e()])],1)])}),0):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-12 p-0 pr-md-2 pl-md-2"},_vm._l((_vm.banners.left),function(banner,index){return (_vm.banners.left && _vm.banners.left.length)?_c('div',{key:index,staticClass:"banner-col h-100 "},[_c('router-link',{staticClass:"banner-url h-100",attrs:{"to":{name: 'subCategories', params: {subCategoryId: banner.url}}}},[_c('div',{staticClass:"boxBanner"},[( banner.typeTitle == 'mp4')?_c('video-player',{ref:"videoPlayerRef",refInFor:true,staticClass:"h-100 w-100",attrs:{"options":{
                        name: 'playerrightdesk_'+index,
                        autoplay: true,
                        loop:true,
                        muted:true,
                        controls:false,
                        sources: [{
                          type: 'application/x-mpegurl',
                          src:  banner.src ,
                          withCredentials: false,
                        }]
                      }}}):_vm._e(),( banner.typeTitle == 'vimeo')?_c('iframe',{staticClass:"h-100 w-100",attrs:{"autoplay":"","src":("https://player.vimeo.com/video/" + (banner.src) + "?autoplay=1&loop=1"),"frameborder":"0","allow":"autoplay; fullscreen","data-ready":"true"}}):_vm._e(),( banner.typeTitle == 'img')?_c('img',{staticClass:"img-fluid",attrs:{"src":banner.src}}):_vm._e()],1),_c('img',{attrs:{"src":"https://crm.gong-online.co.il/img/HomePageBannersTemplateForIframe.jpg","alt":"Move To Category"}}),_c('div',{staticClass:"shopNowBtnDiv"},[_c('input',{staticClass:"shopNowBtn",attrs:{"type":"button","value":"SHOP NOW"}})])])],1):_vm._e()}),0),_c('div',{staticClass:"col-md-6 col-12 p-0 pr-md-2 pl-md-2 mt-md-0"},_vm._l((_vm.banners.right),function(banner,index){return (_vm.banners.right && _vm.banners.right.length)?_c('div',{key:index,staticClass:"banner-col h-100"},[_c('router-link',{staticClass:"banner-url h-100",attrs:{"to":{name: 'subCategories', params: {subCategoryId: banner.url}}}},[_c('div',{staticClass:"boxBanner"},[( banner.typeTitle == 'mp4')?_c('video-player',{ref:"videoPlayerRef",refInFor:true,staticClass:"h-100 w-100",attrs:{"options":{
                        name: 'playerrightdesk_'+index,
                        autoplay: true,
                        loop:true,
                        muted:true,
                        controls:false,
                        sources: [{
                          type: 'application/x-mpegurl',
                          src:  banner.src ,
                          withCredentials: false,
                        }]
                      }}}):_vm._e(),( banner.typeTitle == 'vimeo')?_c('iframe',{staticClass:"h-100 w-100",attrs:{"autoplay":"","src":("https://player.vimeo.com/video/" + (banner.src) + "?autoplay=1&loop=1"),"frameborder":"0","allow":"autoplay; fullscreen","data-ready":"true"}}):_vm._e(),( banner.typeTitle == 'img')?_c('img',{staticClass:"img-fluid",attrs:{"src":banner.src}}):_vm._e()],1),_c('img',{attrs:{"src":"https://crm.gong-online.co.il/img/HomePageBannersTemplateForIframe.jpg","alt":"Move To Category"}}),_c('div',{staticClass:"shopNowBtnDiv"},[_c('input',{staticClass:"shopNowBtn",attrs:{"type":"button","value":"SHOP NOW"}})])])],1):_vm._e()}),0)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }