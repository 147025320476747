<template>
  <div class="input-group">

    <template v-if="smallRegistered">
      <div class="valid-feedback">
        תודה על ההרשמה
      </div>
    </template>

    <template v-else>
      <div class="input-group-prepend">
        <button class="btn btn-primary" type="button" @click.prevent="onSmallRegistration">
          הרשמה
        </button>
      </div>

      <input
        type="email"
        placeholder="הכנס כתובת מייל"
        :class="['form-control', {'is-invalid': isError}]"
        v-model="email"
      >
      <div class="invalid-feedback">
        {{ smallRegisterError || 'משהו השתבש' }}
      </div>
    </template>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'SmallRegistration',

  data() {
    return {
      email: '',
      isError: false
    }
  },

  computed: {
    smallRegistered() {
      return this.$store.getters.smallRegistered
    },
    smallRegisterError() {
      return this.$store.getters.smallRegisterError
    },
  },

  validations: {
    email: { required, email },
  },

  methods: {
    async onSmallRegistration() {
      if(this.$v.$invalid) {
        this.isError = true
        this.$v.$touch()
        return false
      }

      const form = new FormData()
      form.append('email', this.email)

      await this.$store.dispatch('smallRegistration', {form})

      this.isError = this.smallRegisterError !== ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.block-body .input-group .form-control{
  direction: rtl;
}
.invalid-feedback, .valid-feedback{
  text-align: right;
}
.valid-feedback{
  display: block;
}

@include media-breakpoint-down(sm) {
  .block-body .input-group {
    margin-bottom: 1.6rem;
  }
}

</style>
