<template>
  <div :class="['cart-good', {'cart-good-err': cartErrMsg}]">
    <div class="good-media">
      <router-link :to="getRoute">
        <img :src="product.image" :alt="product.title" />
      </router-link>

      <button class="btn btn-delete-from-cart" v-if="canDelete" @click.prevent="deleteFromCart" v-text="'הסר מוצר'" />
    </div>

    <div class="good-info">
      <h3 class="good-info-title">
        <router-link :to="getRoute" v-text="product.title" />
      </h3>

      <div class="good-info-characteristics">
        <p><b> צבע:</b> {{ product.colorID.title }}</p>

        <p><b> מידה: </b>{{ product.sizeID.title }}</p>

        <SpinButton :product="product" v-if="changeQuantity" />

        <p v-else><b> כמות:</b> {{ product.amount }}</p>
      </div>

      <p><b> מחיר:</b> <b>{{ product.price }} ₪</b></p>

      <p class="cartErr" v-if="cartErrMsg" v-text="cartErrMsg.msg" />

      <p class="cartErr" v-if="errorQuantity.prodID === product.amount_id" v-text="errorQuantity.msg" />
    </div>
  </div>
</template>

<script>
import cartMixin from '@/mixins/cart.mixin'
import SpinButton from '@/components/ui/SpinButton'

export default {
  name: 'CardProductOrder',

  components: {
    SpinButton,
  },

  mixins: [cartMixin],

  props: ['product', 'changeQuantity', 'canDelete'],

  computed: {
    cartErrMsg() {
      return this.$store.getters.cartErrMsg(this.product.amount_id)
    },

    errorQuantity() {
      return this.$store.getters.errorQuantity
    },

    getRoute() {
      return {
        name: 'product',
        params: {
          subCategoryId: 0,
          productId: this.product.id
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.cart-good{
  padding: 1.4rem .75rem .75rem;
}
.cart-good-err{
  border: 1px solid #dc3545;
  margin-bottom: .5rem;
}
#app .btn-delete-from-cart{
  height: inherit;
}
.good-media{
  width: 6.8rem;
  margin-left: 1.2rem;
}
.good-media a{
  display: block;
}

@include media-breakpoint-down(sm) {
  #cart .cart-good{
    padding: 1rem 1rem .3rem 1rem;
  }
  #cart .good-media{
    width: 6rem;
    margin-left: 1.1rem;
  }
  #cart .good-info-title{
    font-size: .8rem;
  }
  #cart .good-media img{
    margin-bottom: .3rem;
  }
}
</style>
