import api from '@/api'

export default {
  state: {
    seoContent: {},
    content: {},
  },

  mutations: {
    setSeoContent(state, seoContent) {
      state.seoContent = seoContent;
    },
    setContent(state, content) {
      state.content = content;
    },
  },

  actions: {
    async getContent({ commit }, payload) {
      try{
        const content = await api.content.get(payload)
        commit('setSeoContent', content.seo)
        commit('setContent', content)
      } catch (e) {
        console.log(e, 'getContent')
      }
    },
  },

  getters: {
    seoContent: state => state.seoContent,
    content: state => state.content,
  }
}
