import api from '@/api'

export default {
  state: {
    globalSettings: [],
    headerText: '',
    categories: [],
    subCategory: {},
  },

  mutations: {
    setGlobalSettings(state, globalSettings) {
      state.globalSettings = globalSettings
    },
    setCategories(state, { categories, headerText }) {
      state.categories = categories
      state.headerText = headerText
    },
    setSubCategory(state, subCategory) {
      state.subCategory = subCategory
    },
  },

  actions: {
    async getGlobalSettings({ commit }) {
      try {
        let res = await api.categories.globalSettings()
        if(res.strip.length) {
          const strip = res.strip.map(s => `<i>${s}</i>`)
          commit('setGlobalSettings', strip)
        }
      } catch (e) {
        console.log(e, 'getGlobalSettings')
      }
    },

    async getCategories({ commit }) {
      commit('setLoading', true)
      try{
        const { menu, headerText } = await api.categories.all()

        const categories = menu.map(category => ({
          ...category,
          firstImage: process.env.VUE_APP_IMAGE_URL+'/'+category.var1
        }))

        commit('setCategories', { categories, headerText })
        commit('setLoading', false)
      } catch (e) {
        commit('setLoading', false)
        console.error(e, 'getCategories')
      }
    },

    async getSubCategory({dispatch, commit, getters}, {subCategoryId}) {
      try{
        await dispatch('getCategories')

        const findCat = getters.categories.find(category => category.id === parseInt(subCategoryId))

        if(findCat) {
          commit('setSubCategory', findCat)
        } else {
          getters.categories.forEach(category => {
            const finsSubCat = category.subMenu.find(subMenu => subMenu.id === parseInt(subCategoryId))
            if(finsSubCat) {
              commit('setSubCategory', finsSubCat)
              return
            }
          })
        }

      } catch (e) {
        console.error(e, 'getSubCategory')
      }
    },
  },

  getters: {
    globalSettings: state => state.globalSettings,

    headerText: state => state.headerText,

    categories: state => state.categories,

    subCategory: state => state.subCategory,
  }
}
