import api from '@/api'

export default {
  state: {
    details: {},
    updateDetailsUserStatus: false,
    saveCheckoutStatus: false,
    orderId: null,
    payplusIframe: '',
    paypal : '',
    form: null,
    placesCities: [],
    placesAddresses: [],
    voucherStatus: null,

    errorsFname: {},
    errorsLname: {},
    errorsEmail: {},
    errorsPhone: {},
    errorsAreaPhone: {},
    errorsStreet: {},
    errorsDiraNum: {},
    errorsHouseNum: {},
    errorsCity: {},
    errorsDelivery: {},
    errorsPayment: {},
    errorsCart: [],
    allErrors: {},
    returnPaypal:{},
    paymentStatus: false,
    thankYou: {},
    orderForFacebookMethod: {},
  },

  mutations: {
    setPlacesCities(state, cities){
      state.placesCities = cities
    },
    setPlacesAddresses(state, address){
      state.placesAddresses = address
    },
    setDetails(state, details) {
      state.details = details
    },
    setPaypalReturn(state,resp) {
      state.returnPaypal = resp
    },
    setUpdateDetailsUserStatus(state, {status, form}) {
      state.updateDetailsUserStatus = status
      state.form = form
    },
    setSaveCheckoutStatus(state, { status, orderId, payplusIframe, paypal, voucher }) {
      state.saveCheckoutStatus = status
      state.orderId = orderId
      state.payplusIframe = payplusIframe
      state.paypal = paypal
      state.voucherStatus = voucher
    },
    setErrorDetails(state, payload) {
      state.errorsFname = payload.fname
      state.errorsLname = payload.lname
      state.errorsEmail = payload.email
      state.errorsPhone = payload.phone
      state.errorsAreaPhone = payload.areaPhone
      state.errorsStreet = payload.street
      state.errorsDiraNum = payload.diraNum
      state.errorsHouseNum = payload.houseNum
      state.errorsCity = payload.city
      state.errorsDelivery = payload.delivery
      state.errorsPayment = payload.payment
      state.allErrors = payload.allErrors
    },
    setErrorsCart(state, err) {
      state.errorsCart = err
    },
    clearErrors(state) {
      state.errorsFname = {}
      state.errorsLname = {}
      state.errorsEmail = {}
      state.errorsPhone = {}
      state.errorsStreet = {}
      state.errorsDiraNum = {}
      state.errorsHouseNum = {}
      state.errorsCity = {}
      state.errorsDelivery = {}
      state.errorsPayment = {}
    },
    setPaymentStatus(state, paymentStatus) {
      state.paymentStatus = paymentStatus
    },
    setThankYou(state, thankYou) {
      state.thankYou = thankYou
    },
    setOrderForFacebookMethod(state, orderForFacebookMethod) {
      state.orderForFacebookMethod = orderForFacebookMethod
    },
  },

  actions: {
    async getPlacesCitiesFromGoogle({ commit }, search){
      try{
        const res = await api.checkout.getPlacesCitiesFromGoogle(search)
        if(res.status){
          commit('setPlacesCities', (res.cities ? res.cities: []) )
        }
      }catch(e){
        console.log(e, 'getPlacesCitiesFromGoogle')
      }
    },
    async getPlacesAdressFromGoogle({ commit }, {search , city}){
      try{
        const res = await api.checkout.getPlacesAdressFromGoogle(search, city)
        if(res.status){
          commit('setPlacesAddresses', (res.adress ? res.adress : []) )
        }
      }catch(e){
        console.log(e, 'getPlacesCitiesFromGoogle')
      }
    },

    async getDetails({commit}) {
      try {
        const details = await api.checkout.details()
        commit('setDetails', details)
      } catch (e) {
        console.log(e, 'getDetails')
      }
    },

    async paypal({commit}) {
      try {
        const resp = await api.checkout.paypal()
        commit('setDetails', resp)
      } catch (e) {
        console.log(e, 'getDetails')
      }
    },

    async updateDetailsUser({commit}, {form}) {
      
      try {
        const status = await api.checkout.updateDetailsUser(form)
        commit('setUpdateDetailsUserStatus', {status, form})
        return;
      } catch (e) {
        console.log(e, 'updateDetailsUser')
      }
    },

    async saveCheckout({dispatch, commit}, {form}) {
      try {
        const response = await api.checkout.saveCheckout(form)
        commit('clearErrors')
        commit('setSaveCheckoutStatus', response)

        if(!response.status) {
          if(Array.isArray(response.errors)) {
            const payload = await dispatch('preparePayloadDetailsErr', response)
            commit('setErrorDetails', payload)
          } else {
            if(response.errors.section === 'cart') {
              commit('setErrorsCart', response.errors.err)
            }
          }
        }
      } catch (e) {
        console.log(e, 'saveCheckout')
      }
    },

    preparePayloadDetailsErr({}, status) {
      const detailsErr = status.errors.find(err => err.section === 'details')

      return {
        fname: detailsErr.err.find(err => err.key === 'fname') || {},
        lname: detailsErr.err.find(err => err.key === 'lname') || {},
        email: detailsErr.err.find(err => err.key === 'email') || {},
        phone: detailsErr.err.find(err => err.key === 'phone') || {},
        areaPhone: detailsErr.err.find(err => err.key === 'areaPhone') || {},
        street: detailsErr.err.find(err => err.key === 'street') || {},
        diraNum: detailsErr.err.find(err => err.key === 'diraNum') || {},
        houseNum: detailsErr.err.find(err => err.key === 'houseNum') || {},
        city: detailsErr.err.find(err => err.key === 'city') || {},
        delivery: detailsErr.err.find(err => err.key === 'delivery') || {},
        payment: detailsErr.err.find(err => err.key === 'payment') || {},
        allErrors: detailsErr.err.filter(err => err.key !== 'payment'),
      }
    },

    async getPayment({commit}, {form}) {
      try {
        const paymentStatus = await api.checkout.payment(form)
        commit('setPaymentStatus', paymentStatus)
      } catch (e) {
        console.log(e, 'getPayment')
      }
    },

    async getThankYou({commit}) {
      try {
        const thankYou = await api.checkout.thankYou()
        if(thankYou.status) {
          commit('setThankYou', thankYou)
        }
      } catch (e) {
        console.log(e, 'getThankYou')
      }
    },

    async getOrderForFacebookMethod({commit, getters}, orderId) {
      try {
        const orderForFacebookMethod = await api.checkout.orderForFacebookMethod(orderId)
        console.log('Facebook Method')
        // console.log(orderForFacebookMethod, orderId , 'getOrderForFacebookMethod')
        if(orderForFacebookMethod.status) {
          commit('setOrderForFacebookMethod', orderForFacebookMethod)
        }
      } catch (e) {
        console.log(e, 'getOrderForFacebookMethod')
      }
    },
  },

  getters: {
    placesAddresses: state => state.placesAddresses,

    placesCities: state => state.placesCities,
    
    details: state => state.details,

    updateDetailsUserStatus: state => state.updateDetailsUserStatus,

    form: state => state.form,

    saveCheckoutStatus: state => state.saveCheckoutStatus,
    voucherStatus: state => state.voucherStatus,
    orderId: state => state.orderId,
    payplusIframe: state => state.payplusIframe,

    errorsCart: state => state.errorsCart,

    errorsFname: state => state.errorsFname,
    errorsLname: state => state.errorsLname,
    errorsEmail: state => state.errorsEmail,
    errorsPhone: state => state.errorsPhone,
    errorsAreaPhone: state => state.errorsAreaPhone,
    errorsStreet: state => state.errorsStreet,
    errorsDiraNum: state => state.errorsDiraNum,
    errorsHouseNum: state => state.errorsHouseNum,
    errorsCity: state => state.errorsCity,
    errorsDelivery: state => state.errorsDelivery,
    errorsPayment: state => state.errorsPayment,
    allErrors: state => state.allErrors,

    paymentStatus: state => state.paymentStatus,
    paypal: state => state.paypal,
    paypaldata: state => state.setPaypalReturn,
    thankYou: state => state.thankYou,
    orderForFacebookMethod: state => state.orderForFacebookMethod,
  }
}
