<template>
  <div class="sub-menu">
    <div class="sub-menu-col">
      <img :src="category.firstImage" alt="" />
    </div>

    <div class="sub-menu-col">
      <img :src="category.firstImage" alt="" />
    </div>

    <div class="sub-menu-col">
      <h3 class="sub-menu-title" v-text="category.titleHEB" />

      <ul :class="['list-unstyled', 'sub-menu-ul', {row: category.subMenu.length > 6}]" v-if="category.subMenu.length">
        <li v-for="subMenu of category.subMenu" :key="subMenu.id" :class="{'col-md-6': category.subMenu.length > 6}">
          <router-link :to="{name: 'subCategories', params: { subCategoryId: subMenu.id, fromMenu: true, scrollTop: true }}">
            {{ subMenu.titleHEB }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubMenu',

  props: ['category'],
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.sub-menu {
  width: 100%;
  max-width: 77.9rem;
  display: flex;
  background-color: #fff;
  border: 1px solid #E1E1E1;
  padding: 2.5rem;
  position: relative;
  z-index: 11;
}
.sub-menu-col {
  margin-right: 1.5rem;
}
.sub-menu-col:last-child {
  margin-right: 0;
  text-align: right;
  width: 100%;
}
.sub-menu-col img{
  width: 23.1rem;
}
.sub-menu-ul{
  flex-direction: row-reverse;
}
.sub-menu-ul li {
  margin-bottom: 0.6rem;
}
.sub-menu-ul a {
  font-size: 0.9rem;
  color: #000;
}
.sub-menu-title {
  margin-bottom: 1rem;
}

@include media-breakpoint-down(md) {
  .sub-menu-col img{
    width: 11.1rem;
  }
}
</style>
