<template>
  <footer>
    <div class="container-fluid">
      <div class="footer-container">
        <div class="row">
          <div class="col-md-3">
            <div class="block">
              <div class="block-header">
                <h3 class="block-title" v-text="'הצטרפו למסיבה'" />
              </div>

              <div class="block-body">
                <SmallRegistration />
                <img src="../assets/images/shape.svg" alt="" />
              </div>

              <div class="block-footer">
                <ul class="list-inline list-unstyled socials">
                  <li class="list-inline-item">
                    <a href="https://www.facebook.com/gong.fashion/" target="_blank" title="facebook">
                      <i class="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="https://www.tiktok.com/@gongfashion" target="_blank" title="tiktok">
                      <i class="fab fa-tiktok" />
                    </a>
                  </li>
                  <li class="list-inline-item">
                    <a href="https://www.instagram.com/gongfashion/" target="_blank" title="instagram">
                      <i class="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col" v-for="(item, index) of footer" :key="index">
            <div class="block">
              <div class="block-header">
                <h3 class="block-title" v-text="item.titleHEB" />
              </div>
              <div v-for="(subItem, indexSub) of item.subMenu" :key="indexSub">
                <ul class="list-unstyled footer-menu">
                  <li>
                    <router-link v-if="subItem.url" :to="{path: subItem.url}" v-text="subItem.titleHEB" />
                    <span v-else v-text="subItem.titleHEB" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-bottom-desktop">
      <div class="footer-logo-wittix">
        <div class="logo-wittix">
          <span v-text="'Made by'" />
          <br>
          <a href="https://www.wittix.co.il" target="_blank">
            <img src="../assets/images/Wittix_logo.svg" alt="" />
          </a>
        </div>
      </div>
      <div class="text-footer">
        <span> 2020 <span> בע"מ </span> Gong <span> כל הזכויות שמורות ל </span></span>
      </div>
      <div class="logo-footer">
        <img src="@/assets/images/Payplus.png" />
        <img src="@/assets/images/American.png" />
        <img src="@/assets/images/Visa.png" />
        <img src="@/assets/images/Mastercard.png" />
        <img src="@/assets/images/PCIFooter.png" />
        <span class="text-logo-footer" v-text="'הקנייה מאובטחת בתקן'" />
      </div>
    </div>

    <div class="footer-bottom-mobile">
      <div class="pci">
        <img src="@/assets/images/PCIFooter.png">
        <span v-text="'הקנייה מאובטחת בתקן'" />
      </div>
      <div class="logo-footer-mobile">
        <img src="@/assets/images/Payplus.png" />
        <img src="@/assets/images/American.png" />
        <img src="@/assets/images/Visa.png" />
        <img src="@/assets/images/Mastercard.png" />
      </div>
      <div class="text-footer-mobile">
        <span>2020<span style="direction:rtl"> בע"מ </span> Gong <span style="direction:rtl"> כל הזכויות שמורות ל</span></span>
      </div>
      <div class="logo-wittix-mobile">
        <span v-text="'Made by'" />
        <a href="https://www.wittix.co.il" target="_blank" class="pl-2">
          <img src="../assets/images/Wittix_logo.svg" alt="" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import SmallRegistration from '@/components/auth/SmallRegistration'

export default {
  name: 'Footer',

  components: {
    SmallRegistration
  },

  computed:{
    footer(){
      return this.$store.getters.footer.reverse()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

footer {
  padding-bottom: 1.5rem;
  background-color: #fff;
  position: relative;
  z-index: 10;
}
.footer-container{
  padding: 2.6rem 0;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
}
footer .col-md-3:last-child .block{
  border-right: none;
}
footer .container-fluid{
  margin-left: 3.1rem;
  margin-right: 3.1rem;
  max-width: inherit;
  width: inherit;
}
.footer-menu {
  margin-top: .2rem;
  margin-bottom: .2rem;
  text-align: right;
}
.footer-menu a{
  color: #000;
  line-height: 2;
}
.footer-menu li{
  direction: rtl;
  font-size: .9rem;
}
.footer-bottom-desktop{
  margin-left: 3.1rem;
  margin-right: 3.1rem;
  max-width: inherit;
  width: inherit;
  padding-top: 1.2rem;
  text-align: center;
  padding-left: 15px;
}
.text-footer{
  display: inline-flex;
  margin-bottom: .5rem;
  font-size: .9rem;
}
.logo-footer img{
  margin-left: .4rem;
  margin-right: .4rem;
}
.text-logo-footer{
  margin-left: .4rem;
  font-size: .9rem;
}
.footer-logo-wittix{
  position: relative;
  top: .7rem;
}
.logo-wittix{
  position: absolute;
  text-align: left;
}
.logo-wittix span{
  font-size: .7rem;
}
.span-marging{
  margin-right: .2rem;
}
.footer-bottom-mobile{
  display: none;
}

@include media-breakpoint-down(sm) {
  footer{
    padding-bottom: 2rem;
  }
  footer .container-fluid{
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .footer-container {
    padding: 0;
    border-top: none;
    border-bottom: none;
  }
  .block{
    border-right: none;
    padding-right: 0;
    text-align: center;
  }
  .block-title{
    font-size: 1.35rem;
    text-align: center;
  }
  .block-header {
    margin-bottom: .8rem;
  }
  .block-body {
    margin-bottom: 1rem;
  }
  .socials a{
    font-size: 2rem;
  }
  .block-footer ul{
    margin-bottom: 0;
  }
  .footer-container .col-md-3:first-child .block-title{
    text-align: right;
  }
  .footer-container .col-md-3:first-child .block {
    margin-bottom: 1.8rem;
  }
  .footer-menu{
    margin-top: 0;
    text-align: center;
  }
  .footer-menu li{
    font-size: .7rem;
  }
  .footer-bottom-desktop{
    display: none;
  }
  .footer-bottom-mobile{
    display: block;
    text-align: center;
    padding-top: 3rem;
    font-size: .7rem;
  }
  .footer-bottom-mobile .pci span {
    margin-left: .7rem
  }
  .logo-footer-mobile img{
    margin: 1rem .4rem;
  }
  .text-footer-mobile{
    margin-top: .5rem;
  }
  .logo-wittix-mobile{
    margin-top: 1.5rem;
  }
  .footer-container .row .col:nth-child(2) {
    display: none;
  }
}
</style>
