<template>
  <section id="newArrivals" class="site-section" v-if="sliderProducts.length">
    <div class="section-header">
      <MarqueeComponent
        :fromMain="true"
        :text="'<span>NEW ARRIVALS</span>'"
        :bgColor="'transparent'"
        :fontColor="'#000000'"
      />
    </div>

    <div class="container-fluid">
      <div class="section-body">
        <HooperSliderProducts :products="sliderProducts" :settings="settings" :fromMainPage="true" />
      </div>

      <div class="section-footer">
        <router-link :to="{name: 'subCategories', params: {subCategoryId: 45}}" class="btn btn-primary">
          Shop New Arrivals
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import MarqueeComponent from '@/components/MarqueeComponent'
import HooperSliderProducts from '@/components/HooperSliderProducts'

export default {
  name: 'NewArrivals',

  components: {
    MarqueeComponent, HooperSliderProducts
  },

  data() {
    return {
      settings: {
        itemsToShow: 4,
        itemsToSlide: 1,
        initialSlide: 0,
        infiniteScroll: false,
        wheelControl: false,
        keysControl: false,
        info: false,
      },
    }
  },

  computed: {
    sliderProducts() {
      return this.$store.getters.sliderProducts
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

#newArrivals {
  position: relative;
  z-index: 10;
  background-color: #fff;
}
#newArrivals.site-section {
  padding-top: 2.4rem;
}
.site-section{
  padding-bottom: 0;
}
#newArrivals .btn-primary{
  text-transform: uppercase;
}

@include media-breakpoint-down(sm) {
  .section-footer{
    display: none;
  }
  #newArrivals.site-section{
    padding-top: 0.9rem;
  }
  .arrows {
    width: 1.4rem;
    height: 1.5rem;
    background-size: .4rem;
  }
}

@include media-breakpoint-up(xl) {
  #newArrivals .container-fluid {
    margin-left: 17.1rem;
    margin-right: 17.1rem;
    max-width: inherit;
    width: inherit;
  }
}
</style>
