import api from '@/api'

export default {
  state: {
    colors: [],
    color: null,
    price: null,
  },

  mutations: {
    setColors(state, colors) {
      state.colors = colors
    },
    setColor(state, color) {
      state.color = color
    },
    setPrice(state, price) {
      state.price = price
    },
  },

  actions: {
    async getColors({ commit }, subCategoryId) {
      commit('setLoading', true)

      try{
        const colors = await api.colors.get(subCategoryId)

        commit('setColors', colors)
        commit('setLoading', false)
      } catch (e) {
        commit('setLoading', false)
        console.log(e, 'getSizes')
      }
    },
  },

  getters: {
    colors: state => state.colors,
    color: state => state.color,
    price: state => state.price,
  }
}
