import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { layout: 'Main', class: 'app-main' },
    component: Home
  },
  {
    path: '/cart',
    name: 'cart',
    meta: { class: 'app-cart' },
    component: () => import('@/views/Cart')
  },
  {
    path: '/login',
    name: 'login',
    props: true,
    meta: { class: 'app-login' },
    component: () => import('@/views/Login')
  },
  {
    path: '/register',
    name: 'register',
    props: true,
    meta: { class: 'app-register' },
    component: () => import('@/views/Register')
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    meta: { class: 'app-forgotPassword' },
    component: () => import('@/views/ForgotPassword')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { class: 'app-profile', requiresAuth: true},
    component: () => import('@/views/Profile')
  },
  {
    path: '/favorites',
    name: 'favorites',
    meta: { class: 'app-favorite', requiresAuth: true},
    component: () => import('@/views/Favorites')
  },
  {
    path: '/checkout',
    name: 'checkout',
    props: true,
    meta: { class: 'app-checkout' },
    component: () => import('@/views/Checkout')
  },
  {
    path: '/payments',
    name: 'payments',
    meta: { class: 'app-payments' },
    component: () => import('@/views/Payments')
  },
  {
    path: '/content/:contentId',
    name: 'content',
    meta: { class: 'app-general' },
    component: () => import('@/views/General')
  },
  {
    path: '/search',
    name: 'search',
    meta: { search: true, class: 'app-search' },
    component: () => import('@/views/Search')
  },
  {
    path: '/sub-category-:subCategoryId',
    name: 'subCategories',
    props: true,
    meta: { subCategory: true, class: 'app-subCategories' },
    component: () => import('@/views/Categories')
  },
  {
    path: '/sub-category-:subCategoryId/product-:productId',
    name: 'product',
    props: true,
    meta: { class: 'app-product' },
    component: () => import('@/views/Product')
  },
  {
    path: '/payment-true',
    name: 'PaymentTrue',
    component: () => import('@/views/PaymentTrue')
  },
  {
    path: '/thankyou',
    name: 'ThankYou',
    component: () => import('@/views/ThankYou')
  },
  {
    path: '/:token/logintoken',
    name: 'loginToken',
    props: true,
    component: () => import('@/views/LoginToken')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  }
})

// this route requires auth, check if logged in
// if not, redirect to login page.
router.beforeEach((to, from, next) => {
  if(store.getters.isLogin) {
    if(to.name === 'login') next({ name: 'profile' })
    else next()
  } else {
    if(to.meta.requiresAuth) {
      if(to.name === 'favorites') {
        next({ name: 'login', params: { favoritePage: true } })
      } else {
        next({ name: 'login' })
      }
    } else {
      next()
    }
  }
})

export default router
