<template>
  <div id="home">
    <MainBlock>
      <Header>
        <MainMenu/>
      </Header>
    </MainBlock>

    <NewArrivals/>

    <Banners/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import MainMenu from '@/components/menu/MainMenu'
import MainBlock from '@/components/home/MainBlock'
import NewArrivals from '@/components/home/NewArrivals'
import Banners from '@/components/home/Banners'

export default {
  name: 'Home',

  components: {
    Header, MainMenu, MainBlock, NewArrivals, Banners
  },

  metaInfo() {
    return {
      title: this.seoHome.title !== ''
        ? this.seoHome.title
        : process.env.VUE_APP_NAME+' | עמוד הבית | '+process.env.VUE_APP_NAME_HEB,
      meta: [
        { name: 'keywords', content: this.seoHome.keywords ? this.seoHome.keywords : '' },
        { name: 'description', content: this.seoHome.description ? this.seoHome.description : '' }
      ],
    }
  },

  computed: {
    seoHome() {
      return this.$store.getters.seoHome
    }
  },

  async created() {
    await this.$store.commit('setProducts', [])
    await this.$store.commit('setFilteredProducts', [])
    // await this.$store.dispatch('getDataForHomePage')
  }
}
</script>
<style lang="scss" scoped>

</style>
