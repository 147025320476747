import api from '@/api'

export default {
  state: {
    cart: {},
    delivery: null,
    cartErrMsg: [],
    cartAdded: false,
    errorQuantity: {},
    voucherErrMsg: ''
  },

  mutations: {
    setCart(state, cart) {
      state.cart = {
        products: cart.products.map(product => ({
          ...product,
          image: process.env.VUE_APP_IMAGE_URL+'/'+product.img
        })),
        delivery: cart.delivery,
        totalVoucher: cart.totalVoucher,
        totalProds: cart.totalProds,
        total: cart.total,
        voucher: {
          code: (cart.voucher ? cart.voucher.code : ''),
          type_discount: (cart.voucher ? cart.voucher.type_discount : ''),
          amount: (cart.voucher ? cart.voucher.amount : 0),
          setDate: (cart.voucher ? cart.voucher.setDate : ''),

        }
      }
    },

    setDelivery (state, delivery) {
      state.delivery = delivery
    },

    changeCartAdded(state, value) {
      state.cartAdded = value
    },

    setErrorCartFromCheckout(state, errors) {
      errors.forEach(err => {
        state.cartErrMsg.push({id: err.catalog_amount_id, msg: err.msg})

        const index = state.cart.products.findIndex(product => product.amount_id === err.catalog_amount_id)
        if(index !== -1) {
          state.cart.products[index].errorMsg = err.msg
        }
      })
    },

    setErrorQuantity(state, {msg, prodID}){
      state.errorQuantity = {msg, prodID}
    },

    clearCart(state) {
      state.cart = {
        products: [],
        delivery: {},
        total: 0
      }
    },
    voucherErrMsg( state, msg ){
      if(msg && msg.status == false){
        state.voucherErrMsg = msg.msg
      }else{
        state.voucherErrMsg = ''
      }
    }
  },

  actions: {
    async getCart({ commit }) {
      commit('setLoading', true)

      try {
        const cart = await api.cart.get()
        commit('setCart', cart)
        commit('setDelivery', cart.delivery)
        commit('setLoading', false)
      } catch (e) {
        commit('setLoading', false)
        console.log(e, 'getCart')
      }
    },

    async checkVouchers({commit}, form) {
      const resp = await api.coupons.checkVouchers(form)
      if(resp.status){
        const cart = await api.cart.get()
        commit('setCart', cart)
        commit('setDelivery', cart.delivery)
        commit('voucherErrMsg', '')
      }else{
        commit('voucherErrMsg', resp)
      }
    },

    async cancelVouchers({commit}) {
      const resp = await api.coupons.cancelVouchers()
      if(resp){
        const cart = await api.cart.get()
        commit('setCart', cart)
        commit('setDelivery', cart.delivery)
      }
    },

    async addToCart({ commit }, {form}) {
      try {
        commit('changeCartAdded', true)
        const productsCart = await api.cart.add(form)

        if(productsCart.status === false) {
          commit('setErrorQuantity', {
            msg: productsCart.msg,
            prodID: parseInt(form.get('amount_id'))
          })
        } else {
          commit('setCart', productsCart)
          commit('setErrorQuantity', {})
        }
      } catch (e) {
        console.log(e, 'addToCart')
      }
    },

    async updateDelivery({ commit, dispatch }, {form}) {
      try {
        const cart = await api.cart.updateDelivery(form)
        commit('setDelivery', cart.cart.delivery)
      } catch (e) {
        console.log(e, 'updateDelivery')
      }
    },

    async deleteFromCart({ commit }, {form}) {
      try {
        const productsCart = await api.cart.delete(form)
        commit('setCart', productsCart)
      } catch (e) {
        console.log(e, 'deleteFromCart')
      }
    },
  },

  getters: {
    cart: state => state.cart,
    cartAdded: state => state.cartAdded,
    errorQuantity: state => state.errorQuantity,
    cartErrMsg: state => idProd => state.cartErrMsg.find(cartErr => cartErr.id === idProd),
    cartErrArr: state => state.cartErrMsg,
    voucherErrMsg: state => state.voucherErrMsg,
  }
}
