<template>
  <div id="productInfo">
    <h1 class="product-title" v-text="product.titleHEB" />

    <p class="price">
      <span class="shekel" v-text="'₪'" />
      {{ product.webPriceHEB }}
    </p>

    <p class="price-old" v-if="product.storePriceHEB">
      <span class="shekel" v-text="'₪'" />
      {{ product.storePriceHEB }}
    </p>

    <div class="filters">
      <div class="filter-colors">
        <p class="filter-title" v-text="'צבע'" />

        <div class="filter-btns">
          <div class="filter-btn" v-for="(color, index) of product.colors" :key="index">
            <b-button
              :title="color.title"
              :id="'btn-color-'+index"
              :class="['gong-color', {
                selectedColor: product.defaultProd.colorID.id === color.id && color.amount > 0,
                'btn-color-null': color.amount === 0,
                'btn-color-black': color.id === 30
              }]"
              @click="selectColor(color, $event)"
            >
              <span :style="getStyleColor(color.color)" />
            </b-button>

            <b-tooltip custom-class="tooltip-filter-color" placement="bottom" :target="'btn-color-'+index">
              {{ color.title }}
            </b-tooltip>
          </div>
        </div>
      </div>

      <div class="filter-size">
        <p class="filter-title" v-text="'מידה'" />
        <p class="filter-title-mobile" v-text="':בחרי את המידה שלך'" />

        <div class="filter-btns">
          <div class="filter-btn" v-for="(size, index) of product.sizes" :key="index">
            <b-button
              variant="outline-secondary"
              :title="size.title"
              :id="'btn-size-'+index"
              :class="['btn-size', {'btn-size-null': size.amount === 0}]"
              @click="selectSize(size, $event)"
            >
              {{ size.title }}
            </b-button>
            <b-tooltip custom-class="tooltip-filter-size" placement="bottom" :target="'btn-size-'+index">
              <span v-if="size.amount === 0" v-text="'אזל במלאי'" />
              <span v-if="size.amount > 0" v-text="size.title" />
            </b-tooltip>
          </div>
        </div>
      </div>
    </div>

    <div class="actions">
      <div>
        <FavoriteProductComponent :active="product.favorite" :toggle="toggle" />

        <a :href="'https://api.whatsapp.com/send?text='+href+' '+product.titleHEB+' '+textWhatsapp" class="btn btn-icon btn-whatsapp">
          WhatsApp-שתפי ב
        </a>
      </div>

      <button class="btn btn-primary btn-block" @click.prevent="toCart" :disabled="sizeId === null" v-if="!this.selectRemideMe">
        <div class="spinner-border text-light" role="status" v-if="loading">
          <span class="sr-only" v-text="'Loading...'" />
        </div>
        {{ textButton }}
      </button>

      <!-- <div v-if="saveProductForRemid !== null"> {{ (saveProductForRemid.msg ? saveProductForRemid.msg: 'ss') }}</div> -->
      <form class="gong-form remidmelater pt-4 pb-4 mb-4" @submit.prevent="reminderme" autocomplete="off" method="post" v-if="!!this.selectRemideMe">
        <div class="col-12">
          <div class="row justify-content-center" v-if="!remindBol && !saveProductForRemid">
            <div class="col-12 text-center">
              <div class="row justify-content-center">
                <div class="col-12 text-center" style="color:white;font-size:1.8rem">
                  אזל מהמלאי
                </div>
                <div class="col-12 text-center mb-2" style="color:white;">
                  {{(
                    reactApp && user.fname ? 'עדכנו אותי בנוטיפיקיישן' : 'עדכנו אותי במסרון כשהפריט חוזר למלאי'
                  )}}
                </div>
                <div class="col-3 col-md-3 p-0 text-center" style="">
                  <button type="submit" class="btn p-0" style="font-size:1.0rem;height:3.7rem;background:#dadada;width: 100%;">
                    <!-- <div class="spinner-border text-light" role="status" v-if="false">
                      <span class="sr-only" v-text="'Loading...'" />
                    </div> -->
                  עדכנו אותי
                  </button>
                </div>
                <div class="col-12" style="color:white;font-size:15px;margin-top:5px;direction: rtl;" v-if="!reactApp && !!user.fname && !!user.phone">
                    היי {{user.fname}},
                    המספר ששמור אצלנו הוא: {{user.phone}}
                  </div>
                <div class="col-8 p-0 text-center" v-if="!user.fname && !user.phone">
                  <input
                    style="height:3.7rem;"
                    type="number"
                    name="reminderme"
                    :class="['form-control']"
                    placeholder="מספר פלאפון"
                    v-model="remidMePhone"
                  >
                </div>
                <div class="col-12 mt-2 mb-0" style="color:red;">
                  {{this.remindErrMsg}}
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="remindBol && saveProductForRemid === null">
              <div class="col-12 text-center" style="color:white;font-size:1.2rem;"> נרשמת כבר למוצר זה </div>
          </div>
          <div class="row" v-if="saveProductForRemid !== null && saveProductForRemid.msg">
            <div class="col-12 text-center" style="color:white;">
              {{ (saveProductForRemid.msg ? saveProductForRemid.msg: 'ds') }}
            </div>
          </div>
        </div>
      </form>

      <div class="space-error" v-if="errorQuantity.msg" v-text="errorQuantity.msg" />
    </div>

    <div id="product-accordion">
      <b-card no-body>
        <b-card-header header-tag="header" role="tab">
          <b-button block href="#" v-b-toggle.accordion-1 variant="link" v-text="'פרטים נוספים'" />
        </b-card-header>
        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
          <b-card-body class="accordion-text">
            <div v-if="'defaultProd' in product">
              <p v-if="'modelName' in product.defaultProd && product.defaultProd.modelName !== undefined && 'modelSize' in product.defaultProd && product.defaultProd.modelSize !== undefined">
                <span v-if="product.defaultProd.modelSize">{{ textSize }}: {{ product.defaultProd.modelSize.title }}</span>
              </p>
              <p v-if="'modelName' in product.defaultProd && product.defaultProd.modelName !== undefined && 'modelSize' in product.defaultProd && product.defaultProd.modelSize !== undefined">
                <span v-if="product.defaultProd.modelName">{{ textName }}: {{ product.defaultProd.modelName.title }}</span>
              </p>
              <p v-if="'fabricType' in product.defaultProd && product.defaultProd.fabricType !== undefined && product.defaultProd.fabricType">
                {{ textClose }}: {{ product.defaultProd.fabricType.title }}
              </p>
              <br />
              <div class="descriptions" v-if="product.textHEB" v-html="product.textHEB" />
              <SizeTableModal />
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body v-if="product.delivery && product.delivery.textHEB !== undefined && product.delivery.textHEB !== ''">
        <b-card-header header-tag="header" role="tab">
          <b-button block href="#" v-b-toggle.accordion-2 variant="link" v-text="'משלוחים והזמנות'" />
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body class="accordion-text" v-html="product.delivery.textHEB" />
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import { eventEmitter } from '@/main'
import SizeTableModal from '@/components/product/SizeTableModal'
import FavoriteProductComponent from '@/components/favorite/FavoriteProductComponent'

export default {
  name: 'ProductInfo',

  props: ['isModal', 'toggle'],

  components: {
    SizeTableModal, FavoriteProductComponent
  },

  data() {
    return {
      loading: false,
      colorId: null,
      defaultProdId: null,
      sizeId: null,
      textButton: 'אנא בחרי מידה',
      textName: 'שם הדוגמנית',
      textSize: 'מידה הדוגמנית',
      textClose: 'סוג הבד',
      textWhatsapp: 'GONGתראי פריט שאהבתי ב',
      href: window.location.href,
      selectRemideMe: false,
      remidMePhone: '',
      saveProdToLater: [],
      remindBol: false,
      remindErrMsg: '',
      reactApp: (window.ReactNativeWebView || document.ReactNativeWebView ? true : false)
    }
  },

  computed: {
    subCategory() {
      return this.$store.getters.subCategory
    },
    product() {
      return this.$store.getters.product
    },
    completeLook() {
      return this.$store.getters.completeLook
    },
    selectedColorId() {
      return this.$store.getters.selectedColorId
    },
    errorQuantity() {
      return this.$store.getters.errorQuantity
    },
    saveProductForRemid() {
      return this.$store.state.products.saveProductForRemid
    },
    user() {
      return this.$store.state.auth.user
    },
  },

  mounted() {
    if(this.product.defaultProd) {
      this.defaultProdId = this.product.defaultProd.id
    }
  },

  created() {
    if(this.product.defaultProd) {
      this.defaultProdId = this.product.defaultProd.id
    }

    const viewContent = {
      content_type: 'product',
      content_ids: this.product.id,
      value: this.product.webPriceHEB,
      content_name: this.product.titleHEB,
      currency: 'ILS',
    }

    if(this.subCategory) {
      viewContent.content_category = this.subCategory.titleHEB
    }

    fbq('track', 'ViewContent', viewContent);
  },

  methods: {
    getStyleColor(color) {
      return `background-color: #${color}; border-color: #${color === 'ffffff' ? 'd3d3d3' : color}`
    },

    async reminderme() {
      const form = new FormData()
      this.remindErrMsg = '';

      if(!this.remidMePhone && !this.user.phone && !this.reactApp){
        this.remindErrMsg = 'יש להזין מספר פלאפון'
        return;
      }
      if(this.reactApp && !this.user && !this.user.phone && !this.remidMePhone ){
        this.remindErrMsg = 'יש להזין מספר פלאפון'
        return;
      }
      form.append('prodID', this.product.id)
      form.append('amount_id', this.defaultProdId)
      form.append('sizeID', this.sizeId)
      form.append('phone', (this.user.phone ? this.user.phone : (this.remidMePhone ? this.remidMePhone : 0) ))
      if(this.user && this.user.fname && this.user.phone){
        form.append('user_id', this.user.id)
      }
      form.append('colorID', this.colorId ? this.colorId : this.product.defaultProd.colorID.id)
      if(this.reactApp){
        form.append('fromapp', 1)
      }
      this.saveProdToLater.push(this.defaultProdId)
      this.remindBol = false
      this.remidMePhone = ''
      await this.$store.dispatch('remindMe', {form})
    },

    async toggleFavorite(){
      if(this.$store.getters.isLogin){
        eventEmitter.$emit('toggleFavorite', !this.favorite);
        await this.$store.dispatch('updateFavProduct',{ productId: this.product.id })
        this.favorite = !this.favorite
      } else{
        this.$router.push({ name: 'login'})
      }
    },

    selectSize(size, event) {
      if(this.errorQuantity.msg)
        this.$store.commit('setErrorQuantity', {})
      if(size.amount !== 0) {
        this.selectRemideMe = false;
        this.$el.querySelectorAll('.btn-size').forEach(btn => btn.classList.remove('selectedSize'))
        event.target.classList += ' selectedSize'
        let color = (this.colorId ? this.colorId : this.product.defaultProd.colorID.id)
        const defaultProd = this.product.products.find(p => p.sizeID.id === size.id && p.colorID.id === color)
        this.defaultProdId = defaultProd.id

        this.sizeId = size.id
        this.textButton = 'הוספה לסל'
      }else{
        this.$el.querySelectorAll('.btn-size').forEach(btn => btn.classList.remove('selectedSize'))
        event.target.classList += ' selectedSize'
        this.sizeId = size.id

        let color = (this.colorId ? this.colorId : this.product.defaultProd.colorID.id)
        const defaultProd = this.product.products.find(p => p.sizeID.id === size.id && p.colorID.id === color)
        this.defaultProdId = defaultProd.id

        this.remindBol = !!this.saveProdToLater.find(p => p === defaultProd.id);
        this.selectRemideMe = true;
      }
      this.$store.state.products.saveProductForRemid = null
    },

    async selectColor(color, event) {
      this.selectRemideMe = false
      this.sizeID = null
      this.textButton = 'אנא בחרי מידה'
      if(this.errorQuantity.msg)
        this.$store.commit('setErrorQuantity', {})

      this.sizeId = null
       this.$el.querySelectorAll('.btn-size').forEach(btn => btn.classList.remove('selectedSize'))

       this.$el.querySelectorAll('.gong-color').forEach(btn => btn.classList.remove('selectedColor'))
      // if(color.amount > 0) {
      //   event.target.classList += ' selectedColor'
      // }
      event.target.classList += ' selectedColor'
      this.product.defaultProd = this.product.products.find(p => p.colorID.id === color.id)
      this.product.defaultProd.mainImage = this.product.defaultProd.images[0]
      if(this.product.defaultProd.video_link_new && this.product.defaultProd.video_link_new.link){
        var newurl = this.product.defaultProd.video_link_new.link.split('/');
        if(newurl.length > 1){
          newurl = newurl[2]
        }else if(newurl.length == 1){
          newurl = newurl[0];
        }
        this.product.defaultProd.mainVideo = this.product.defaultProd.video_link_new && this.product.defaultProd.video_link_new.link !== '' ? newurl : null
      }
      // this.product.defaultProd.mainVideo = this.product.defaultProd.video_link_new && this.product.defaultProd.video_link_new.link !== ''
      //   ? `https://crm.gong-online.co.il/${this.product.defaultProd.video_link_new.link}`
      //   : null

      this.defaultProdId = this.product.defaultProd.id

      const selectProducts = this.product.products.filter(p => p.colorID.id === color.id)

      this.product.sizes = selectProducts.map(p => p.sizeID).sort(function(a, b) {
          var nameA = a.title.toUpperCase(); // ignore upper and lowercase
          var nameB = b.title.toUpperCase(); // ignore upper and lowercase
          if(Number(nameA)){
            if (nameA < nameB) {
              return -1;
            }
          }else{
            if (nameA < nameB) {
              return -1;
            }
          }
          return 0;
        })

      this.$store.commit('setSelectedColorId', color.id)
      this.colorId = color.id

      await this.$store.dispatch('getCompleteLook', {
        productId: this.product.id,
        colorId: color.id
      })
    },

    async toCart() {
      this.loading = true

      const form = new FormData()
      form.append('prodID', this.product.id)
      form.append('amount_id', this.defaultProdId)
      form.append('sizeID', this.sizeId)
      form.append('colorID', this.colorId ? this.colorId : this.product.defaultProd.colorID.id)
      form.append('amount', 1)

      await this.$store.dispatch('addToCart', {form})

      if(this.errorQuantity.msg === undefined) {
        eventEmitter.$emit('openSmallCart', true)
      }

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

#productInfo {
  font-family: 'Varela Round', sans-serif;
  position: relative;
}
#productInfo button:focus,
#productInfo a:focus,
#productInfo .btn:focus,
.productInfo.focus,
.productInfo:focus{
  box-shadow: none;
  outline: none;
}
#productInfo .card {
  border: none;
  border-radius: 0;
}
.product-title{
  text-align: right;
  font-size: 1.5rem;
  margin-bottom: 1.4rem;
  font-weight: 400;
}
.price{
  text-align: right;
  margin-bottom: 1.1rem;
  font-size: 1.7rem;
}
.price i{
  font-size: 1.3rem;
}
.price-old{
  text-align: right;
  margin-bottom: 1.1rem;
  font-size: 1.2rem;
  color: #989898;
  text-decoration: line-through;
  margin-top: -1rem;
}
.filters{
  margin-bottom: 2rem;
}
.filter-colors{
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 1rem;
  height: 2rem;
}
.filter-colors .filter-btn{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.filter-size {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.filters p{
  display: block;
  color: #8D8D8D;
  font-size: 0.8rem;
  margin-bottom: 0;
}
.filter-title{
  margin-left: 1.3rem;
}
.filter-colors .filter-title {
  margin-bottom: 0;
}
.filter-size .filter-title {
  margin-bottom: .7rem;
}
.filter-size p:last-child{
  margin-right: .8rem;
}
.filter-colors{
  min-height: 2.6rem;
}
.filter-size .filter-btns{
  min-height: 3.6rem;
  margin-bottom: 1rem;
  flex-direction: row-reverse;
}
.filters .filter-title-mobile{
  display: none;
  color: #000;
}
#productInfo .actions .btn-icon {
  padding: 0;
  margin-bottom: 1rem;
  font-family: 'Varela Round', sans-serif;
  font-weight: normal;
}
.actions .btn-favorite {
  margin-right: 1.5rem;
}
#productInfo .btn{
  border-radius: 0;
  border: none;
}
#productInfo .actions .btn-block{
  padding: 1.1rem 1rem 1.2rem;
  font-family: 'Varela Round', sans-serif;
  font-weight: normal;
  font-size: 1.9rem;
  background-color: #000;
  color: #fff;
  line-height: 1;
}
#productInfo .filter-size .filter-btns .filter-btn{
  margin-bottom: 0;
  margin-left: .8rem;
}
#productInfo .filter-btns .btn-size{
  padding: .6rem;
  border: 2px solid #E1E1E1;
  min-width: 2.3rem;
  background-color: transparent;
  color: #8D8D8D;
  font-weight: normal;
  line-height: 1;
  margin-left: 0;
  margin-bottom: 0;
}
.descriptions{
  margin-bottom: 1.2rem;
  text-align: right;
}
.descriptions-mobile{
  display: none;
}
.model-info{
  direction:rtl;
  text-align : right;
}
#productInfo .filter-btns-color .selectedColor{
  position: relative;
}
#productInfo .filter-btns-color .selectedColor:before {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  padding: .7rem;
  border: 1px solid #989898;
  border-radius: 50%;
  position: absolute;
  top: -.34rem;
  left: -.34rem;
}
#productInfo .filter-btns .selectedSize {
  border-color: #989898;
}
.actions .spinner-border{
  width: 1.7rem;
  height: 1.7rem;
  vertical-align: middle;
  display: inline-block;
}
.invalid-feedback{
  display: block;
  text-align: center;
}
#app .card-body.accordion-text{
  text-align: right;
  direction: rtl;
}
.accordion-text p{
  margin-bottom: 0;
  text-align: right;
}
#product-accordion p {
  direction: rtl;
}
.space-error{
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ef383b;
}
#productInfo .filter-btns .btn-color-null {
  position: relative;
  white-space: pre;
}
.btn-color-null:after {
  content: '';
  position: absolute;
  width: 137%;
  top: 2px;
  left: 0;
  height: 50%;
  transform: rotate(45deg);
  display: block;
  border-bottom: 2px solid black;
}
.btn-color-black:after{
  border-bottom-color: #fff;
}
#productInfo .filter-btns .btn-size-null{
  position: relative;
  // border: 2px solid #898989;
  white-space: pre;
}
.btn-size-null:after {
  content: '';
  position: absolute;
  width: 107%;
  top: 1px;
  left: 0;
  height: 50%;
  transform: rotate(20deg);
  display: block;
  border-bottom: 2px solid black;
  // content: "";
  //   position: absolute;
  //   width: 130%;
  //   top: 2px;
  //   left: -2px;
  //   height: 50%;
  //   transform: rotate(36deg);
  //   display: block;
  //   border-bottom: 2px solid #000;
}
.actions-none-product{
  background-color: #000;
  padding: 1.8rem 3.5rem;
  margin-bottom: 1.5rem;
  color: #fff;
  text-align: center;
  direction: rtl;
}
.actions-none-product .btn-secondary{
  background-color: #D9D9D9;
  color: #000;
  font-family: 'Varela Round', sans-serif;
}
.remidmelater{
  padding: 0px 60px 0px 60px;
  background: #000;
}

@include media-breakpoint-down(sm) {
  .remidmelater{
    padding: 0px 0px 0px 0px;
    background: #000;
  }
  .descriptions-mobile{
    display: block;
  }
  .product-title{
    text-align: center;
    font-size: 1.1rem;
    margin-top: 1rem;
    margin-bottom: .4rem;
  }
  .price, .price-old {
    text-align: center;
    margin-bottom: .8rem;
    font-size: 1.1rem;
  }
  .price i {
    font-size: 1rem;
  }
  .price-old{
    font-size: .8rem;
    margin-top: -.6rem;
  }
  .filter-size, .filter-colors {
    flex-direction: column;
  }
  .filter-size{
    align-items: center;
  }
  .filter-size .filter-btns{
    margin-bottom: 0;
  }
  .filter-colors{
    margin-bottom: 1.3rem;
    justify-content: center;
  }
  .filter-colors .filter-title {
    display: none;
  }
  #productInfo .filter-size .filter-btns .filter-btn:last-child{
    margin-left: 0;
  }
  .filter-title, .filter-size p:last-child {
    margin-left: 0;
    margin-right: 0;
  }
  .filter-size p, .filter-size a{
    margin-bottom: .8rem;
  }
  .filter-size a{
    margin-top: .6rem;
  }
  .filter-size .filter-title{
    display: none;
  }
  .filters .filter-title-mobile{
    display: block;
  }
  #productInfo .filter-size .filter-btns button{
    border-width: 1px;
    font-size: .7rem;
    min-width: 2rem;
  }
  #productInfo .actions .btn-block{
    font-size: .9rem;
    padding-top: .6rem;
    padding-bottom: .6rem;
    margin-bottom: 1.7rem;
    line-height: 2;
  }
  .actions{
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 1rem;
  }
  .actions div{
    display: flex;
    justify-content: space-around;
  }
  #productInfo .actions .btn-icon{
    font-weight: normal;
    margin-bottom: 0;
  }
  .actions div .btn-icon:first-child{
    margin-right: 0;
  }
  .btn-icon:before{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.4rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  .descriptions {
    margin-bottom: 1.3rem;
  }
  #app .filter-btns .selectedColor:before{
    top: -.25rem;
    left: -.25rem;
  }
  #product-accordion{
    margin-bottom: 2.3rem;
  }
  .product-video{
    display: none;
  }
  .model-info{
    display: none;
  }
  .spinner-border{
    margin-right: .5rem;
  }
  .filter-colors .filter-btns{
    justify-content: center;
  }
  .actions-none-product {
    padding: 1rem;
  }
  .actions-none-product h3 {
    font-size: 1.3rem;
  }
  .actions-none-product p {
    font-size: .8rem;
  }
  .actions-none-product .btn {
    padding-bottom: .6rem !important;
  }
}
</style>
