<template>
  <div class="quick-view-modal">
    <a href="#" class="quick-view" @click.prevent="getProd">
      Quick view
    </a>

    <b-modal
      v-model="showQuickViewModal"
      id="quickViewModal"
      size="lg"
      hide-backdrop
      hide-header-close
      hide-footer
    >
      <template v-slot:modal-header>
        <button class="btn btn-close" @click.prevent="showQuickViewModal = false"></button>
      </template>

      <Loading v-if="loading" />

      <div class="row" v-else>
        <div class="col-md-6">
          <SliderProducts :toggle="toggle" :isModal="true" />
        </div>
        <div class="col-md-6">
          <ProductInfo :toggle="toggle" :isModal="true" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SliderProducts from '@/components/product/SliderProducts'
import ProductInfo from '@/components/product/ProductInfo'

export default {
  name: 'QuickViewModal',

  props: ['product', "toggle"],

  components: {
    SliderProducts, ProductInfo
  },

  data() {
    return {
      loading: false,
      showQuickViewModal: false
    }
  },

  computed:{
    productRequest() {
      return this.$store.getters.product
    },
  },

  watch: {
    showQuickViewModal(newShowQuickViewModal) {
      if (!newShowQuickViewModal) {
        this.$store.commit('setCompleteLook', {})
        this.$store.commit('setProduct', {})
      }
    },

    'product.favorite': {
      handler: function (after) {
        this.productRequest.favorite = after;

        deep: true
      }
    },
  },

  methods:{
    async getProd() {
      this.loading = true
      this.showQuickViewModal = !this.showQuickViewModal
      await this.$store.dispatch('getProduct', { productId: this.product.id })
      this.loading = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

#product .quick-view-modal{
  display: none;
}
#quickViewModal .modal-header .btn{
  padding: 0;
}
.quick-view-modal .row .col-md-6:first-child{
  flex: 0 0 52.6%;
  max-width: 52.6%;
}
.quick-view-modal .row .col-md-6:last-child{
  flex: 0 0 47.4%;
}
.quick-view {
  display: none;
  position: absolute;
  bottom: .9rem;
  right: .8rem;
  color: #000;
  font-size: .8rem;
  width: 3rem;
  text-align: center;
  line-height: 1;
  text-decoration: none;
}
.quick-view:after{
  content: '+';
  font-weight: bold;
  font-size: 1rem;
  position: absolute;
  right: -.2rem;
  bottom: -.6rem;
}
.card-media:hover .quick-view{
  display: block;
}

@include media-breakpoint-down(sm) {
  .quick-view, .card-media:hover .quick-view{
    display: none;
  }
}
</style>
