import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta'
import VueTagManager from 'vue-tag-manager'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from '@/App'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import { Slider } from 'element-ui'
import Loading from '@/components/Loading'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';


export const eventEmitter = new Vue()

Vue.config.productionTip = false

Vue.component(Slider.name, Slider)
Vue.component('Loading', Loading)
Vue.component('v-select', vSelect)

Vue.use(Vuelidate)
Vue.use(VueMeta)
Vue.use(VueTagManager, {gtmId: 'GTM-NTXGZTS'})
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(require('vue-moment'))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

console.log(
  '╔═══╦══╦╗─╔╦═══╗\n' +
  '║╔══╣╔╗║╚═╝║╔══╝\n' +
  '║║╔═╣║║║╔╗─║║╔═╗\n' +
  '║║╚╗║║║║║╚╗║║╚╗║\n' +
  '║╚═╝║╚╝║║─║║╚═╝║\n' +
  '╚═══╩══╩╝─╚╩═══╝'
)
console.log('%c !עצור/עצרי ', 'font-size: 30px; font-weight: bold; color: red')
console.log('%c .זוהי תכונה בדפדפן שמיועדת למפתחים. אם מישהו אמר לך להעתיק ולהדביק משהו כאן כדי להפעיל תכונה או כדי "לפרוץ" לחשבון של מישהו, מדובר בהונאה והיא תתן להם גישה לחשבון שלך', 'font-size: 17px; font-weight: bold; color: #000;')
