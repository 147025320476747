import api from '@/api'

export default {
  state: {
    orders: [],
  },

  mutations: {
    get_order(state, orders) {
      state.orders = orders;
    },
  },

  actions: {
    async getOrder({ commit }) {
      try{
        const footer = await api.order.getUserOrders()
        commit('get_order', footer.orders)
      } catch (e) {
        console.log(e, 'get_order')
      }
    },
  },

  getters: {
    order: state => state.orders,
  }
}
