<template>
  <header>
    <div id="topMessage" ref="topMessage" v-html="headerText" />

    <div class="container-fluid">
      <div :class="['top', { topFixed: fixedTop }]">
        <ul class="list-unstyled list-inline cart-and-favorite">

          <SmallCart />

          <li class="list-inline-item favorite-container">
            <router-link :to="{name: 'favorites', params: {favPage: true}}">
              <span class="icons icons-favorite" />
            </router-link>
          </li>
        </ul>

        <div id="logo">
          <a href="/" v-text="'GONG'" />
        </div>

        <ul class="list-unstyled list-inline registration-and-bntmenu">
          <li class="list-inline-item header-registration" v-if="!isLogin">
            <router-link :to="{name: 'login'}" v-text="'התחברות'" />
          </li>

          <li v-if="isLogin" class="list-inline-item header-logged">
            שלום {{users.fname}},<br>
            <router-link :to="{name: 'profile'}" v-text="'לאזור האישי שלך | '" />
            <a href="#" @click="logout()" v-text="'התנתקות'" />
          </li>

          <SidebarMenu />
        </ul>
      </div>
    </div>

    <slot/>

  </header>
</template>

<script>
import device from 'current-device'
import SmallCart from '@/components/SmallCart'
import SidebarMenu from '@/components/menu/SidebarMenu'
import {eventEmitter} from '@/main'

export default {
  name: 'Header',

  components: {
    SmallCart, SidebarMenu
  },

  data() {
    return {
      device,
      fixedTop: false,
      sidebarMenu: false,
      sidebarCart: false
    }
  },

  computed:{
    users() {
      return this.$store.getters.user
    },
    isLogin(){
      return this.$store.getters.isLogin
    },
    headerText(){
      return this.$store.getters.headerText
    },
  },

  methods: {
    scrollHandle() {
      if(this.$refs.topMessage) {
        const scrollHeight = this.$refs.topMessage.scrollHeight
        this.fixedTop = window.scrollY >= scrollHeight
      }
    },

    async logout(){
      await this.$store.dispatch('logout');
      window.location.href = '/'
    }
  },

  created() {
    if(device.mobile()) {
      if(this.$route.name === 'home'){
        document.addEventListener('scroll', this.scrollHandle)
      } else {
        this.fixedTop = true
      }
    }

    eventEmitter.$on('handleSidebarMenu', sidebarMenu => {
      this.sidebarMenu = sidebarMenu;
    })

    eventEmitter.$on('handleSidebarCart', sidebarCart => {
      this.sidebarCart = sidebarCart;
    })

  const self = this;
 

    if(window.ReactNativeWebView) {
        window.addEventListener("message", function(event) {
        const data = JSON.parse(event.data)
            if(data){
              if( data.act == 'cart'){
                self.$router.push({ name: 'cart', params: {} })
              }else if(data.act == 'homePage'){
                self.$router.push({ name: 'home', params: {} })
              }else if(data.act == 'favorites'){
                if(self.isLogin){
                  self.$router.push({ name: 'favorites', params: {} })
                }else{
                  window.ReactNativeWebView.postMessage('{"act":"login","url":""}');
                }
              }else if(data.act == 'user'){
                if(self.isLogin){
                  self.$router.push({ name: 'profile', params: {} })
                }else{
                  window.ReactNativeWebView.postMessage('{"act":"login","url":""}');
                }
              }
            }
      }, false);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to{
  transform: translateX(-10px);
  opacity: 0;
}

#topMessage {
  padding: .7rem 0;
  background-color: #000;
  text-align: center;
  color: #fff;
}
header{
  width: 100%;
}
header .top{
  display: flex;
  justify-content: space-between;
  padding: 1.4rem 0 0;
  margin-bottom: .25rem;
}
header .topFixed{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: .5rem 15px .26rem;
  z-index: 11;
  border-bottom: 1px solid #E1E1E1;
}
#home header .top{
  margin-bottom: 0;
}
#home header .navbar{
  padding-top: 0;
}
#home #logo{
  padding-top: .8rem;
}
#home #logo a{
  line-height: 1;
}
#home header{
  position: relative;
  background: url('../assets/images/white.png') repeat-x top;
  background-size: contain;
  margin-bottom: 2rem;
}
header .top ul{
  margin-bottom: 0;
}
.registration-and-bntmenu{
  padding-top: .15rem;
}
header .top a{
  color: #000;
}
#logo a{
  font-size: 3.8rem;
  line-height: 1.4;
}
#logo a:hover{
  text-decoration: none;
}
header .navbar {
  margin-bottom: .95rem;
}
.text-top-header{
  margin-right: .5rem;
}
.close .icon-alt {
  font-size: .5rem;
  font-weight: bold;
}
.header-logged{
  direction:rtl;
  text-align:right
}
.cart-and-favorite, #logo, .registration-and-bntmenu{
  flex: 0 0 33.333333%;
}
#logo{
  text-align: center;
}
.registration-and-bntmenu{
  text-align: right;
}
@include media-breakpoint-down(sm) {
  #innerLayout header{
    margin-top: 3.9rem;
  }
  #innerLayout header{
    display: flex;
    flex-direction: column-reverse;
  }
  header .container-fluid{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  header .top{
    flex-direction: row-reverse;
    padding-top: .5rem;
  }
  header .top .list-inline-item a{
    display: block;
    text-align: center;
    line-height: 1;
  }
  .app-product #topMessage, .app-login #topMessage, .app-register #topMessage,
  .app-checkout #topMessage, .app-profile #topMessage, .app-forgotPassword #topMessage {
    display: none;
  }
  #topMessage p{
    font-size: .6rem;
  }
  #app #logo {
    padding-top: .35rem;
  }
  #logo a {
    font-size: 2rem;
  }
  .icons-heart-white{
    width: .6rem;
    height: .6rem;
    margin: 0 .3rem;
  }
  .text-top-header {
    margin-right: .3rem;
  }
  .header-registration {
    display: none;
  }
  .favorite-container{
    display: none;
  }

  .header-logged{
    display: none;
  }
  .cart-and-favorite, #logo, .registration-and-bntmenu{
    flex: inherit;
  }
}
</style>
