<template>
  <section id="banners" class="site-section">
    <div class="container">
      <div class="section-body">
        <div class="row" v-if="banners.center && banners.center.length">
          <div class="col-md-12" v-for="(banner, index) of banners.center" :key="index">
            <div class="banner-col">
              <router-link :to="{name: 'subCategories', params: {subCategoryId: banner.url}}" class="banner-url">
                <img v-if="device.desktop()" :src="banner.file" :alt="banner.titleHEB">
                <img v-if="device.mobile()" :src="banner.mobile_file" :alt="banner.titleHEB">
              </router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-md-6 col-12 p-0 pr-md-2 pl-md-2">
                <div class="banner-col h-100 " v-if="banners.left && banners.left.length" v-for="(banner, index) of banners.left" :key="index">
                  <router-link :to="{name: 'subCategories', params: {subCategoryId: banner.url}}" class="banner-url h-100">
                   <div class="boxBanner">
                       <video-player 
                        v-if=" banner.typeTitle == 'mp4'"
                        class="h-100 w-100"
                        ref="videoPlayerRef"
                        :options="{
                          name: 'playerrightdesk_'+index,
                          autoplay: true,
                          loop:true,
                          muted:true,
                          controls:false,
                          sources: [{
                            type: 'application/x-mpegurl',
                            src:  banner.src ,
                            withCredentials: false,
                          }]
                        }"
                        />
                        <iframe 
                          v-if=" banner.typeTitle == 'vimeo'"
                          autoplay
                          class="h-100 w-100"
                          :src="`https://player.vimeo.com/video/${banner.src}?autoplay=1&loop=1`"
                          frameborder="0"
                          allow="autoplay; fullscreen"
                          data-ready="true">
                        </iframe>

                      <img 
                        class="img-fluid" 
                        v-if=" banner.typeTitle == 'img'"
                        :src="banner.src"
                      >
                    </div>
                    <img src="https://crm.gong-online.co.il/img/HomePageBannersTemplateForIframe.jpg" alt="Move To Category" />
                    <div class="shopNowBtnDiv">
                      <input class="shopNowBtn" type="button" value="SHOP NOW">
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-md-6 col-12 p-0 pr-md-2 pl-md-2 mt-md-0">
                <div class="banner-col h-100" v-if="banners.right && banners.right.length" v-for="(banner, index) of banners.right" :key="index">
                  <router-link :to="{name: 'subCategories', params: {subCategoryId: banner.url}}" class="banner-url h-100">
                    <div class="boxBanner">
                       <video-player 
                        v-if=" banner.typeTitle == 'mp4'"
                        class="h-100 w-100"
                        ref="videoPlayerRef"
                        :options="{
                          name: 'playerrightdesk_'+index,
                          autoplay: true,
                          loop:true,
                          muted:true,
                          controls:false,
                          sources: [{
                            type: 'application/x-mpegurl',
                            src:  banner.src ,
                            withCredentials: false,
                          }]
                        }"
                        />
                        <iframe 
                          v-if=" banner.typeTitle == 'vimeo'"
                          autoplay
                          class="h-100 w-100"
                          :src="`https://player.vimeo.com/video/${banner.src}?autoplay=1&loop=1`"
                          frameborder="0"
                          allow="autoplay; fullscreen"
                          data-ready="true">
                        </iframe>

                      <img 
                        class="img-fluid" 
                        v-if=" banner.typeTitle == 'img'"
                        :src="banner.src"
                      >
                    </div>
                    <img src="https://crm.gong-online.co.il/img/HomePageBannersTemplateForIframe.jpg" alt="Move To Category" />
                    <div class="shopNowBtnDiv">
                      <input class="shopNowBtn" type="button" value="SHOP NOW">
                    </div>
                    <!-- <video-player 
                      v-if="device.desktop() && banner.file && banner.file.search('/files/') < 0 && banner.file.search('mp4') > 0"
                      class="h-100 w-100"
                      ref="videoPlayerRef"
                      :options="{
                        name: 'playerrightdesk_'+index,
                        autoplay: true,
                        loop:true,
                        muted:true,
                        controls:false,
                        sources: [{
                          type: 'application/x-mpegurl',
                          src: banner.file,
                          withCredentials: false,
                        }]
                      }"
                      />

                      <iframe v-if="device.desktop() && banner.file && banner.file.search('/files/') < 0 && banner.file.search('mp4') < 0 && banner.file.search('jpg') < 0"
                        autoplay
                        class="h-100 w-100"
                        :src="`https://player.vimeo.com/video/${banner.file}?autoplay=1&loop=1`"
                        frameborder="0"
                        allow="autoplay; fullscreen"
                        allowfullscreen=""
                        width="100%"
                        data-ready="true">
                      </iframe>

                    <video-player 
                    v-if="device.mobile() && banner.mobile_file && banner.mobile_file.search('/files/') < 0 && banner.mobile_file.search('mp4') > 0"
                    class="h-100 w-100 sectionTwo"
                    ref="videoPlayerRef"
                    :options="{
                      name: 'playerleftmobile_'+index,
                      autoplay: true,
                      loop:true,
                      muted:true,
                      controls:false,
                      sources: [{
                        type: 'application/x-mpegurl',
                        src: banner.mobile_file,
                        withCredentials: false,
                      }]
                    }"
                    />
                    <div class="iframe-container" v-if="device.mobile() && banner.mobile_file && banner.mobile_file.search('/files/') < 0 && banner.mobile_file.search('mp4') < 0">
                      <iframe 
                        autoplay
                        class="w-100 "
                        :src="`https://player.vimeo.com/video/${banner.mobile_file}?autoplay=1&loop=1`"
                        frameborder="0"
                        allow="autoplay; fullscreen"
                        allowfullscreen=""
                        data-ready="true">
                      </iframe>
                    </div>
                    
                    <img v-if="device.desktop() && banner.file && banner.file.search('jpg') > 0" :src="'https://crm.gong-online.co.il/'+banner.file" :alt="banner.titleHEB">
                    <img v-if="device.mobile() && banner.mobile_file && banner.mobile_file.search('/files/') > 0" :src="banner.mobile_file" :alt="banner.titleHEB"> -->
                    <!-- <div class="shopNowBtnDiv">
                      <input class="shopNowBtn" type="button" value="SHOP NOW">
                    </div> -->
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-md-6 col-12">
            <div class="row h-100">
              <div class="banner-col h-100 " v-if="banners.left && banners.left.length" v-for="(banner, index) of banners.left" :key="index">
                <router-link :to="{name: 'subCategories', params: {subCategoryId: banner.url}}" class="banner-url h-100">
                <video-player 
                  v-if="device.desktop() && banner.file && banner.file.search('/files/') < 0"
                  class="h-100 w-100"
                  ref="videoPlayerRef"
                  :options="{
                    name: 'videoPlayerleftdesk'+index,
                    autoplay: true,
                    loop:true,
                    muted:true,
                    playsinline:false,
                    controls:false,
                    preload:'auto',
                    sources: [{
                      type: 'application/x-mpegurl',
                      src: banner.file,
                      withCredentials: false,
                    }]
                  }"
                  />
                  
                
                  <img v-if="device.desktop() && banner.file && banner.file.search('/files/') > 0" :src="banner.file" :alt="banner.titleHEB">
                  <img v-if="device.mobile() && banner.mobile_file && banner.mobile_file.search('/files/') > 0" :src="banner.mobile_file" :alt="banner.titleHEB">
                  <div class="shopNowBtnDiv">
                    <input class="shopNowBtn" type="button" value="SHOP NOW">
                  </div>
                </router-link>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="row h-100">
              <div class="banner-col" v-if="banners.right && banners.right.length" v-for="(banner, index) of banners.right" :key="index">
                <router-link :to="{name: 'subCategories', params: {subCategoryId: banner.url}}" class="banner-url">
                  <video-player 
                  v-if="device.desktop() && banner.file && banner.file.search('/files/') < 0"
                  class="h-100 w-100"
                  ref="videoPlayerRef"
                  :options="{
                    name: 'playerrightdesk_'+index,
                    autoplay: true,
                    loop:true,
                    muted:true,
                    playsinline:false,
                    controls:false,
                    preload:'auto',
                    sources: [{
                      type: 'application/x-mpegurl',
                      src: banner.file,
                      withCredentials: false,
                    }]
                  }"
                  />
                  <video-player 
                  v-if="device.mobile() && banner.mobile_file && banner.mobile_file.search('/files/') < 0"
                  class="h-100 w-100"
                  ref="videoPlayerRef"
                  :options="{
                    name: 'playerleftmobile_'+index,
                    autoplay: true,
                    loop:true,
                    muted:true,
                    playsinline:false,
                    controls:false,
                    preload:'auto',
                    sources: [{
                      type: 'application/x-mpegurl',
                      src: banner.mobile_file,
                      withCredentials: false,
                    }]
                  }"
                  />
                
                  <img v-if="device.desktop() && banner.file && banner.file.search('/files/') > 0" :src="banner.file" :alt="banner.titleHEB">
                  <img v-if="device.mobile() && banner.mobile_file && banner.mobile_file.search('/files/') > 0" :src="banner.mobile_file" :alt="banner.titleHEB">
                 
                  <div class="shopNowBtnDiv">
                    <input class="shopNowBtn" type="button" value="SHOP NOW">
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import device from 'current-device'
import { videoPlayer } from 'vue-video-player'

export default {
  name: 'Banners',

  components: { videoPlayer },

  data() {
    return {
      device
    }
  },

  computed: {
    banners() {
      return this.$store.getters.banners
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.banner-url{
  position: relative;
}
.boxBanner{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.iframe-container {
  overflow: hidden;
  padding-top: 100%; /* 16:9*/
  position: relative;
}
.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}
.shopNowBtnDiv{
    position: absolute;
    z-index: 99999;
    bottom: 5%;
    width: 100%;
    justify-content: center;
    display: flex;
    height: 100%;
}
.shopNowBtn{
    bottom: 3%;
    position: absolute;
    background: none;
    color: #d4d5d9;
    font-size: 20px;
    border: 1px solid #d4d5d9;
    padding: 0px 15px 0px 15px;
    height: fit-content;
    bottom: '2%';
}

#banners {
  position: relative;
  z-index: 10;
  background-color: #fff;
}
#banners .col-md-6:first-child{
  padding-right: .65rem;
}
#banners .col-md-6:last-child{
  padding-left: .65rem;
}
.site-section{
  padding-top: 2.4rem;
}
.banner-col{
  padding-top: .65rem;
  padding-bottom: .65rem;
}
.container {
  max-width: 1380px;
}

@include media-breakpoint-down(sm) {
  .banner-col{
    padding-top: 0;
  }

  .video-player{
    // height: 320px!important;
    z-index: 0;
  }
  #banners.site-section{
    padding-bottom: 3.1rem;
  }
  .site-section{
    padding-top: 0.65rem;
  }
  #banners .col-md-6:first-child{
    margin-bottom: 3px;
  }
  #banners .col-md-6:first-child, #banners .col-md-6:last-child{
    // padding-right: 0;
    // padding-left: 0;
  }
}

@include media-breakpoint-up(xl) {
  .container {
    max-width: 71.9vw;
  }
}
</style>
