<template>
  <div id="hooperSliderProducts" :class="[{hooperMainPage: fromMainPage}, {hooperInnerPage: !fromMainPage}]">
    <hooper :settings="settingsHooper">
      <slide v-for="(product, index) in products" :key="index" :index="index">
        <CardProduct :product="product" :info="settings.info" />
      </slide>

      <navigation slot="hooper-addons"/>
    </hooper>
  </div>
</template>

<script>
import CardProduct from '@/components/CardProduct'

import { Hooper, Slide, Navigation } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: 'HooperSliderProducts',

  components: {
    CardProduct, Hooper, Slide, Navigation
  },

  props: ['products', 'settings', 'fromMainPage'],

  data() {
    return {
      settingsHooper: {
        ...this.settings,
        shortDrag: false,
        breakpoints: {
          320: { itemsToShow: 2 },
          375: { itemsToShow: 2 },
          400: { itemsToShow: 2 },
          1024: { itemsToShow: this.fromMainPage ? 4 : 3 }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

#hooperSliderProducts.hooperMainPage .hooper{
  height: 27.83rem;
}
#hooperSliderProducts.hooperInnerPage .hooper{
  height: inherit;
}
@include media-breakpoint-down(sm) {
  #hooperSliderProducts.hooperMainPage .hooper{
    height: 14.5rem;
  }
}
</style>
