<template>
    <div class="applicationPopup" v-if="showApp && device.mobile() && !this.react ">
        <div class="row align-items-center">
            <div class="col-auto">
                <a target="_blank" :href="(device.ios() ? 'https://apps.apple.com/il/app/gong/id1541646377' : 'https://play.google.com/store/apps/details?id=com.gongonlineapp' )" class="getapp-link">GET APP</a>
            </div>
             <div class="col p-0">
                 <div class="row">
                    <div class="col-12 titleApp">
                        הורידו את האפליקציה
                    </div>
                    <div class="col-12 sub-titleApp">
                        לחוית קנייה מושלמת
                    </div>
                 </div>
                <span></span>
            </div>
             <div class="col-auto pr-0 popupAppImg">
                <img src="../assets/images/AppIconPREVIEW.jpg.png" class="w-100">
            </div>
            <div class="col-auto pl-2 pr-3 closeAppPop" @click.prevent="closePopupApp">
                <i class="fas fa-times"></i>
            </div>
        </div>
    </div>
</template>
<script>
import Cookie from 'cookie'
import Cookies from 'js-cookie'
import device from 'current-device'
export default {
    
  name: 'ApplicationPopup',

  components: {},

  mixins: [],

  data() {
    return {
        showApp: false,
        device : device,
        react : !!window.ReactNativeWebView
    }
  },

  computed: {
  },

  watch: {
  
  },

  mounted() {
  
  },

  created() {
    const cookieStr = process.browser ? document.cookie : this.app.context.req.headers.cookie
    const cookies = Cookie.parse(cookieStr || '') || {}
    if(!cookies['appPopup']){
        this.showApp = true
    }
    // Cookies.remove('appPopup');
  },

  methods: {
      closePopupApp () {
        Cookies.set('appPopup', '1', { expires: 1 });
        this.showApp = false
      }
  }
}
</script>
<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.applicationPopup{
    position:  fixed;
    bottom: 0;
    color:black;
    z-index: 2321321321;
    text-align: right;
    width: 100%;
    background: white;
    padding: 15px 10px 15px 10px;
    z-index: 99999999999999999999999999999999999999;
    left: 0;
}
.getapp-link{
    background: black;
    color: white;
    padding: 10px;
    font-size: 12px;
}
.titleApp{
    font-size: 16px;
}
.sub-titleApp{
    font-size: 12px;
}

@media (max-width: 321px) {
    .popupAppImg{
        width: 17%;
    }
    .titleApp{
        font-size: 14px;
    }
    .sub-titleApp{
        font-size: 10px;
    }
    .getapp-link{
        background: black;
        color: white;
        padding: 8px;
        font-size: 12px;
    }
}
</style>
