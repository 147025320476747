<template>
  <div class="size-table-modal">
    <a href="#" class="table-size" @click.prevent="showModal = !showModal">
      טבלת מידות
    </a>

    <b-modal
      v-model="showModal"
      size="lg"
      :centered="true"
      hide-backdrop
      hide-header-close
      hide-footer
      content-class="shadow"
      id="tableSize"
    >
      <template v-slot:modal-header>
        <div class="table-size-header">
          <h3>טבלת מידות</h3>
        </div>

        <button class="btn btn-close" @click.prevent="showModal = false">
          סגור
        </button>
      </template>

      <div class="table-responsive">
        <table class="table table-size-content table-bordered table-fixed">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col" v-for="(size, index) of tableSizes" :key="index">
                {{ size.size }}
              </th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td>אורך</td>
            <td v-for="(size, index) of tableSizes" :key="index">
               {{ size.height }}
            </td>
          </tr>
          <tr>
            <td>היקף</td>
            <td v-for="(size, index) of tableSizes" :key="index">
              {{ size.moten }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'SizeTableModal',

  data() {
    return {
      showModal: false
    }
  },

  computed: {
    product() {
      return this.$store.getters.product
    },

    tableSizes() {
      let tableSizes = this.$store.getters.tableSizes
      const count = 10 - tableSizes.length

      for(let i=1; i <= count; i++)
        tableSizes.push({})

      return tableSizes
    },
  },

  watch: {
    async showModal() {
      if(this.showModal) {
        await this.$store.dispatch('getTableSizes', this.product.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.table-size-header{
  padding-top: 3.4rem;
}
.table-size-header h3{
  font-size: 1.4rem;
  text-align: center;
}
#tableSize .btn-close{
  position: absolute;
  top: .7rem;
  right: .7rem;
  padding: 0;
  font-size: .9rem;
}
.table-size-content{
  text-align: center;
  direction: rtl;
}
.table-size-content thead th{
  border-bottom-width: 1px;
  font-size: .8rem;
  padding-left: 0;
  padding-right: 0;
}
.table-size-content tbody td{
  font-size: .8rem;
  padding-left: 0;
  padding-right: 0;
}

.table-size{
  display: block;
  color: #8D8D8D;
  font-size: 0.8rem;
  margin-bottom: 0;
}

@include media-breakpoint-down(sm) {
  .table-size-header h3{
    font-size: 1.1rem;
    margin-bottom: 0;
  }
  #tableSize .modal-body .table-responsive{
    margin-bottom: 0;
  }
  .table-size-content thead th,
  .table-size-content tbody td{
    font-size: .6rem;
  }
  #tableSize .btn-close{
    font-size: .7rem;
    top: 1.1rem;
  }
  #tableSize .btn-close:after{
    font-size: 1.4rem;
  }
  .table-size-header{
    padding-top: 0;
  }
  .size-table-modal{
    margin: .5rem 0;
  }
}
</style>
