<template>
  <a :class="['btn btn-icon btn-favorite', {active: active}]" @click.prevent="toggle" v-text="'Wishlistהוספה ל'" />
</template>

<script>
export default {
  name: 'FavoriteProductComponent',

  props: ['active', 'toggle']
}
</script>

<style scoped>
.btn-icon {
  padding: 0;
  margin-bottom: 1rem;
  font-family: 'Varela Round', sans-serif;
  font-weight: normal;
}
.btn-favorite {
  margin-right: 1.5rem;
}
 .btn{
  border-radius: 0;
  border: none;
}
</style>
