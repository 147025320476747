<template>
  <h2 class="section-title marquee" :style="style">
    <span v-html="getTmp"></span>
  </h2>
</template>

<script>
export default {
  name: 'MarqueeComponent',

  props: ['fromMain', 'text', 'bgColor', 'fontColor'],

  computed: {
    globalSettings() {
      return this.$store.getters.globalSettings
    },

    style() {
      return `background-color: ${this.bgColor}; color: ${this.fontColor};`
    },

    getTmp() {
      const text = this.fromMain ? this.text : this.globalSettings.join(' ')
      let tmp = ''

      for(let i=0; i < 100; i++) {
        this.fromMain
          ? tmp += (i % 2) ? `<b>${text}</b>` : text
          : tmp += text
      }

      return tmp
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translate(-10%, 0);
    transform: translate(-10%, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0)
  }
}

@-moz-keyframes scroll {
  0% {
    -moz-transform: translate(-10%, 0);
    transform: translate(-10%, 0);
  }
  100% {
    -moz-transform: translate(-100%, 0);
    transform: translate(-100%, 0)
  }
}

@keyframes scroll {
  0% {
    transform: translate(-10%, 0);
  }
  100% {
    transform: translate(-100%, 0)
  }
}

.section-title {
  font-family: 'Roboto Light';
  margin-bottom: 2.3rem;
}
.marquee {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
#categories .marquee {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  padding: .8rem 0;
}
.marquee span {
  display: inline-block;
  -webkit-animation: scroll 200s infinite linear running;
  -moz-animation: scroll 200s infinite linear running;
  animation: scroll 200s infinite linear running;
}
.marquee b{
  display: inline-block;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}
.marquee span i{
  font-style: normal;
}
#categories .marquee span{
  -webkit-animation: scroll 500s infinite linear running;
  -moz-animation: scroll 500s infinite linear running;
  animation: scroll 500s infinite linear running;
}

@include media-breakpoint-down(sm) {
  .section-title{
    margin-bottom: .9rem;
    font-size: .8rem !important;
  }
}
</style>
