<template>
  <div :class="classes" id="mainLayout">

    <main class="app-content">
      <transition name="layout">
        <router-view />
      </transition>
    </main>

    <Footer/>
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import mainMixin from '@/mixins/main.mixin'

export default {
  name: 'MainLayout',

  mixins: [mainMixin],

  components: {
    Footer
  },
}
</script>
