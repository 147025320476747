<template>
  <div class="success-registration-modal">
    <b-modal
      v-model="showSuccessRegistrationModal"
      id="successRegistrationModal"
      size="sm"
      :centered="true"
      hide-header-close
      hide-footer
    >
      <template v-slot:modal-header>
        <button class="btn btn-close" @click.prevent="showSuccessRegistrationModal = false" />
      </template>

      <div class="registration-modal-content" v-html="content.textHEB" />
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'SuccessRegistrationModal',

  data() {
    return {
      showSuccessRegistrationModal: true,
      timeOut: null
    }
  },

  computed: {
    content() {
      return this.$store.getters.content
    }
  },

  watch: {
    showSuccessRegistrationModal(newVal) {
      if(!newVal) {
        this.$store.commit('setIsRegistered', false)
        clearTimeout(this.timeOut)
      }
    },
  },

  async mounted() {
    await this.$store.dispatch('getContent', 7)
    this.timeOut = setTimeout(() => this.showSuccessRegistrationModal = false, 3000)
  },
}
</script>
