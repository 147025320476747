import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/auth'
import home from '@/store/home'
import categories from '@/store/categories'
import products from '@/store/products'
import filter from '@/store/filter'
import cart from '@/store/cart'
import footer from '@/store/footer' 
import content from '@/store/content'
import checkout from '@/store/checkout'
import order from '@/store/order'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    }
  },
  actions: {
    setLoading({ commit }, payload) {
      commit('setLoading', payload)
    },
  },
  getters: {
    loading: state => state.loading
  },
  modules: {
    auth,
    home,
    categories,
    products,
    filter,
    cart,
    footer,
    content,
    checkout,
    order
  }
})
