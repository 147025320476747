import api from '@/api'

export default {
  state: {
    footer: [],
  },

  mutations: {
    setFooter(state, footer) {
      state.footer = footer;
    },
  },

  actions: {
    async getFooter({ commit }) {
      try{
        const footer = await api.footer.get()
        commit('setFooter', footer)
      } catch (e) {
        console.log(e, 'setFooter')
      }
    },
  },

  getters: {
    footer: state => state.footer,
  }
}
