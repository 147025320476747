<template>
  <div :class="classes" id="innerLayout">
    <Header>
      <MainMenu/>
    </Header>

    <main class="app-content">
      <transition name="layout">
        <router-view />
      </transition>
    </main>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import MainMenu from '@/components/menu/MainMenu'
import mainMixin from '@/mixins/main.mixin'

export default {
  name: 'InnerLayout',

  mixins: [mainMixin],

  components: {
    Header, Footer, MainMenu
  },
}
</script>
