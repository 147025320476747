<template>
  <section id="mainBlock" ref="mainBlock" class="site-section">
    <!-- <video v-if="video.video" autoplay loop muted playsinline class="fullscreen-bg__video">
      <source src="https://crm.gong-online.co.il/videos/GONG4K.mp4" type="video/mp4" v-if="device.desktop()" /> 
      <source src="https://crm.gong-online.co.il/videos/mobile.mp4" type="video/mp4" v-if="device.mobile() || device.tablet()" />
    </video>

    <div v-else class="fullscreen-bg__video">
      <div class="bg-screen" :style="`background-image: url(${video.desktopBanner})`" v-if="device.desktop()" />
      <div class="bg-screen" :style="`background-image: url(${video.mobileBanner})`" v-if="device.mobile() || device.tablet()" />
    </div> -->

    <!-- <video ref="videoPlayer" muted loop autoplay preload="auto" class="fullscreen-bg__video" /> -->
    
    <video-player class="fullscreen-bg__video" v-if="this.video.videoNewUrl && !vimeo"
        ref="videoPlayerRef"
        :options="playerOptions"
        @ready="onPlayerReady" 
      />
    <iframe 
      v-if="this.video.videoNewUrl && vimeo" 
      allowfullscreen
      autoplay
      style="width:100%;height:100%"
      :src="`https://player.vimeo.com/video/${this.video.videoNewUrl}?autoplay=1&loop=1&title=0&byline=0&portrait=0`" 
      class="fullscreen-bg__video" 
      meute="1"
      frameborder="0" 
      allow="autoplay; fullscreen"
      data-ready="true">
    </iframe>


    <slot />

    <!-- <div v-if="video.video && blackFriday" class="black-friday" /> -->
  </section>
</template>

<script>
import device from 'current-device'
import { videoPlayer } from 'vue-video-player'

export default {
  name: 'MainBlock',

  components: { videoPlayer },

  data() {
    return {
      vimeo: false,
      playerOptions: {
        name: 'player',
        autoplay: true,
        loop:true,
        muted:true,
        playsinline:true,
        controls: false,
        width: 0,
        height:0,
        sources: [{
          type: 'application/x-mpegurl',
          src: '',
          withCredentials: false,
        }],
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        flash: { hls: {withCredentials: false } },
        html5: { hls: {withCredentials: false } },
        poster: this.thumbnail,
      },
      device,
      blackFriday: false
    }
  },

  async mounted () {
    window.vuePlayerModal = this;
  },

  async created() {
    await this.$store.dispatch('getDataForHomePage')

    if(device.desktop() && this.video.videoNewUrl){
      if(this.video.videoNewUrl.search('mp4') >= 0){
          this.playerOptions.sources = [{
            type: 'application/x-mpegurl',
            src: `https://video.gong-online.co.il/hls/${this.video.videoNewUrl}/index.m3u8`
          }]
          this.vimeo = false;
        }else if(this.video.videoNewUrl){
          this.vimeo = true;
          this.playerOptions.sources = [{
            type: 'application/x-mpegurl',
            src: this.video.videoNewUrl
          }]
        }

    }else if(this.video.mobileVideo){
      if(this.video.mobileVideo.search('mp4') >= 0){
        this.playerOptions.sources = [{
          type: 'application/x-mpegurl',
          src: `https://video.gong-online.co.il/hls/${this.video.mobileVideo}/index.m3u8`
        }]
        this.vimeo = false;
      }else{
        this.video.videoNewUrl = this.video.mobileVideo
        this.vimeo = true;
        this.playerOptions.sources = [{
          type: 'application/x-mpegurl',
          src: this.video.mobileVideo
        }]
      }
    }
    // if(this.video.videoNewUrl){
    //   if(device.desktop()){
        
    //   }else if((device.mobile() || device.tablet()) && this.video.mobileVideo ){
        
    //     if(this.video.mobileVideo && this.video.mobileVideo.search('mp4') > 0){
    //       this.playerOptions.sources = [{
    //         type: 'application/x-mpegurl',
    //         src: `https://video.gong-online.co.il/hls/${this.video.mobileVideo}/index.m3u8`
    //       }]
    //       this.vimeo = false;
    //     }else if(this.video.mobileVideo){
    //       this.vimeo = true;
    //       this.playerOptions.sources = [{
    //         type: 'application/x-mpegurl',
    //         src: this.video.mobileVideo
    //       }]
    //     }
    //     // this.playerOptions.sources = [{
    //     //   type: 'application/x-mpegurl',
    //     //   src: `https://video.gong-online.co.il/hls/${this.video.mobileVideo}/index.m3u8`,
    //     //   withCredentials: false,
    //     // }]
    //   }
    // }
  },

  computed: {
     player() {
      return this.$refs.videoPlayerRef.player;
    },
    video() {
      return this.$store.getters.video
    },
  },
  methods: {
    onPlayerReady(player) {
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

#mainBlock {
  background: url('/src/assets/video/video.jpg') no-repeat fixed center;
  background-size: cover;
  height: 54.5rem;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#mainBlock.site-section{
  margin-bottom: 0;
}
.fullscreen-bg__video {
  position: fixed;
  top: 2.9rem;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fullscreen-bg__video .container,
.fullscreen-bg__video .container-fluid{
  height: 100%;
}
.bg-screen{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
}
.container{
  max-width: 1350px;
  padding-right: 0;
  padding-left: 0;
}

@include media-breakpoint-down(sm) {
  #mainBlock {
    height: 81vh;
  }
  .container{
    padding-right: 0;
    padding-left: 0;
  }
}

@include media-breakpoint-down(lg) {
  #mainBlock .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@include media-breakpoint-up(xl) {
  .container{
    padding-right: 0;
    padding-left: 0;
    max-width: 70.35vw;
  }
}
</style>
