<template>
  <div id="loading">
    <div class="spinner-border gong-spinner" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
}
</script>

<style scoped>
#loading{
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 5rem 0;
}
.gong-spinner{
  width: 4rem;
  height: 4rem;
}
</style>
