export default {
  computed: {
    classes() {
      return ['app-main-layout', this.$route.meta.class, { mobile: device.mobile() }, { desktop: device.desktop() }]
    }
  },

  methods: {
    mainImage(images) {
      return images.find(image => image.main).path
    },
    getImageSoldOout(){
      return process.env.VUE_APP_IMAGE_URL+'/img/soldOut.png';
    }
  }
}
