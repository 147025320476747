import _ from 'lodash'
import api from '@/api'

export default {
  state: {
    seoProducts: {},
    banner: {},
    pagesProducts: 1,
    products: [],
    filteredProducts: [],
    product: {},
    completeLook: [],
    searchProducts: [],
    searchField: '',
    tableSizes: [],
    selectedColorId: null,
    favoriteProduct: [],
    scrollPosition: null,
    saveProductForRemid: null
  },

  mutations: {
    setSeoProducts(state, seoProducts) {
      state.seoProducts = seoProducts
    },
    setBanner(state, banner) {
      state.banner = banner
    },
    setPagesProducts(state, pages) {
      state.pagesProducts = pages
    },
    setProducts(state, products) {
      state.products = products
    },
    setFilteredProducts(state, products) {
      state.filteredProducts = products
    },
    setProduct(state, product) {
      product.sizes && product.sizes.sort(function(a, b) {
        var nameA = a.title.toUpperCase(); // ignore upper and lowercase
        var nameB = b.title.toUpperCase(); // ignore upper and lowercase
        if(Number(nameA)){
          if (nameA < nameB) {
            return -1;
          }
        }else{
          if (nameA < nameB) {
            return -1;
          }
        }
        return 0;
      })
      state.product = product
    },
    setCompleteLook(state, completeLook) {
      state.completeLook = completeLook
    },
    setSearchProducts(state, searchProducts) {
      state.searchProducts = searchProducts
    },
    setSearchField(state, searchField) {
      state.searchField = searchField
    },
    setTableSizes(state, tableSizes) {
      state.tableSizes = tableSizes.map(sizes => ({
        ...sizes,
        size: sizes.sizeID[0].title
      }))
    },
    setFavProduct(state, favoriteProducts) {
      state.favoriteProduct = favoriteProducts
    },
    updateFavProduct(state, favoriteProducts) {
      state.favoriteProduct = favoriteProducts
    },
    setSelectedColorId(state, selectedColorId) {
      state.selectedColorId = selectedColorId
    },
    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
    setSaveProductForRemid(state, saveProductForRemid) {
      state.saveProductForRemid = saveProductForRemid
    }
  },

  actions: {
    async getProducts({ dispatch, commit, getters }, { subCategoryId, page, device, colorId }) {
      try{
        const response = await api.products.getByCategory(subCategoryId, page, colorId)
        console.log(response)
        if(response.status) {
          const products = await dispatch('transform', {productsFetch: response.products})

          if(response.banners.length) {
            let isChanged = false
            response.banners.forEach((banner, index) => {
              if(banner.location === 0 && banner.desk !== '') {
                commit('setBanner', banner)
              } else {
                let location = (device === 'desktop') ? banner.location : (banner.location / 2)
                products.splice(isChanged ? location + index - 1 : location, 0, {
                  type: 'banner',
                  banner: device === 'desktop'
                    ? process.env.VUE_APP_IMAGE_URL+'/'+banner.desk
                    : process.env.VUE_APP_IMAGE_URL+'/'+banner.mobile
                })
                isChanged = true
              }
            })
          }
         
          commit('setSeoProducts', response.seo)
          commit('setPagesProducts', response.pages)
          
          let p = (typeof page == "undefined") ? p=products : p= getters.products.concat(products); // infinite scroll;
          commit('setProducts', p )
          commit('setFilteredProducts', p)
        }
      } catch (e) {
        console.log(e, 'getProducts')
      }
    },

    async getFavoriteProducts({dispatch,commit}) {
      commit('setLoading', true)
      try {
        const response = await api.products.favProducts()
        const productsFav = await dispatch('transform', {productsFetch: response.products})

        commit('setFavProduct', productsFav)
        commit('setLoading', false)
      } catch (e) {
        commit('setLoading', false)
        console.log(e, 'getFavoriteProducts')
      }
    },

    async updateFavProduct({commit}, {productId}) {
      try {
        const response = await api.products.updateFavProduct(productId)
      } catch (e) {
        commit('setLoading', false)
        console.log(e, 'getFavoriteProducts')
      }
    },

    async getProduct({ dispatch, commit, state, getters }, { productId }) {
      commit('setLoading', true)

      try{
        let product = await api.products.find(productId)

        if(getters.products.length) {
          const findProductIndex = getters.products.filter(p => p.type !== 'banner').findIndex(p => p.id === product.id)
          product.nextProd = getters.products[findProductIndex + 1] ? getters.products[findProductIndex + 1].id : undefined
          product.prevProd = getters.products[findProductIndex - 1] ? getters.products[findProductIndex - 1].id : undefined
        }

        product.products.map(p => {
          p.sizeID.amount = p.amount
          p.colorID.amount = p.amount
        })

        product.colors = _.uniqBy(product.products.map(p => p.colorID), 'title')

        product.colors = product.colors.map(color => {
          const productColor = product.products.filter(p => p.colorID.id === color.id)
          const colorAmount = productColor.find(p => p.amount > 0)

          return colorAmount ? {...colorAmount.colorID} : {...color}
        })

        var soldout = true;
        product.products = product.products.map(prod => {
         
          if(prod.images.length) {
            prod.images = prod.images.map(image => process.env.VUE_APP_IMAGE_URL+'/'+image).slice(0, 4)
          } else {
            for (let index = 0; index < 4; index++) {
              prod.images.push(process.env.VUE_APP_IMAGE_URL+'/files/NoImage.jpg')
            }
          }

          if(prod.amount > 0){
            soldout = false;
          }
          return {...prod}
        })

        product.defaultProd = state.selectedColorId
          ? product.products.find(p => p.colorID.id === state.selectedColorId) || product.products[0]
          : product.products[0]

        const selectProducts = state.selectedColorId
          ? product.products.filter(p => p.colorID.id === state.selectedColorId)
          : product.products.filter(p => p.colorID.id === product.defaultProd.colorID.id)

        product.sizes = selectProducts.map(p => p.sizeID)

        product.defaultProd.mainImage = product.defaultProd.images[0]
        if(product.defaultProd && product.defaultProd.video_link_new && product.defaultProd.video_link_new.link){
          console.log('product.defaultProd.video_link_new.link' , product.defaultProd.video_link_new.link)
          var newurl = product.defaultProd.video_link_new.link.split('/');
          if(newurl.length > 1){
            newurl = newurl[2]
          }else if(newurl.length == 1){
            newurl = newurl[0];
          }
          product.defaultProd.mainVideo = product.defaultProd.video_link_new && product.defaultProd.video_link_new.link !== '' ? newurl : null
        }
        product.defaultProd.soldOut = soldout


        await dispatch('getCompleteLook', { productId, colorId: product.defaultProd.colorID.id })

        commit('setProduct', product)
        commit('setLoading', false)
      } catch (e) {
        commit('setLoading', false)
        console.log(e, 'getProduct')
      }
    },

    async getCompleteLook({ commit }, { productId, colorId }) {
      try {
        let completeTheLooks = await api.products.completeTheLook(productId, colorId)

        if(completeTheLooks.length) {
          completeTheLooks = completeTheLooks.map(completeTheLook => {
            completeTheLook.products = completeTheLook.products.map(complete => {
              if(complete.images.length) {
                complete.images = complete.images.map(image => process.env.VUE_APP_IMAGE_URL+'/'+image).slice(0, 4)
              } else {
                for (let i = 0; i < 4; i++) {
                  complete.images.push(process.env.VUE_APP_IMAGE_URL+'/files/NoImage.jpg')
                }
              }
              return {...complete}
            })

            completeTheLook.colors =  _.uniqBy(completeTheLook.products.map(p => p.colorID), 'title')

            completeTheLook.defaultProd = {
              ...completeTheLook.products[0],
              mainImage: completeTheLook.products[0].images[0],
            }

            return {...completeTheLook}
          })
        }

        commit('setCompleteLook', completeTheLooks)

      } catch (e) {
        commit('setLoading', false)
        console.log(e, 'getCompleteLook')
      }
    },

    async getSearchProducts({dispatch, commit}, title) {
      try {
        commit('setSearchField', title)

        const productsFetch = await api.products.searchByTitle(title)
        const searchProducts = await dispatch('transform', {productsFetch})

        commit('setFilteredProducts', searchProducts)
        commit('setSearchProducts', searchProducts)
      } catch (e) {
        console.log(e, 'searchProducts')
      }
    },

    async getTableSizes({commit}, id) {
      try {
        const tableSizes = await api.products.tableSize(id)
        commit('setTableSizes', tableSizes)
      } catch (e) {
        console.log(e, 'getTableSizes')
      }
    },

    transform({dispatch, commit}, {productsFetch}) {
      return productsFetch.map(product => {
        if(product.id ==14421){
          console.log(product)
        }
        const colors = _.uniqBy(product.products.map(p => p.colorID), 'title')
        product.favorite = product.favorite !== null

        product.products = product.products.map(prod => {
          if(prod.images.length) {
            prod.images = prod.images.map(image => process.env.VUE_APP_IMAGE_URL+'/'+image).slice(0, 4)
          } 
          // no images
          else {
            for (let index = 0; index < 4; index++) {
              prod.images.push(process.env.VUE_APP_IMAGE_URL+'/files/NoImage.jpg')
            }
          }
          return {...prod}
        })

        const defaultProd = product.products[0]
        defaultProd.mainImage = defaultProd.images[0]
        if(product.defaultProd && product.defaultProd.video_link_new && product.defaultProd.video_link_new.link){
          var newurl = product.defaultProd.video_link_new.link.split('/');
          if(newurl.length > 1){
            newurl = newurl[2]
          }else if(newurl.length == 1){
            newurl = newurl[0];
          }
          defaultProd.mainVideo = product.defaultProd.video_link_new && product.defaultProd.video_link_new.link !== '' ? newurl : null
        }
        // defaultProd.mainVideo = defaultProd.video_link_new && defaultProd.video_link_new.link !== '' ? `https://crm.gong-online.co.il/${defaultProd.video_link_new.link}` : null

        return {...product, colors, defaultProd}
      })
    },

    async remindMe({ commit }, { form }) {
      try {
        const productsSaveLater = await api.products.remidMeLater(form)
        commit('setSaveProductForRemid', {
          status: productsSaveLater.status,
          msg: productsSaveLater.msg
        })
      } catch (e) {
      }
    },
  },

  getters: {
    seoProducts: state => state.seoProducts,

    banner: state => state.banner,

    pagesProducts: state => state.pagesProducts,

    products: state => state.products,

    filteredProducts: state => state.filteredProducts,

    product: state => state.product,

    completeLook: state => state.completeLook,

    searchProducts: state => state.searchProducts,

    searchField: state => state.searchField,

    tableSizes: state => state.tableSizes,

    productFav : state => state.favoriteProduct,

    selectedColorId: state => state.selectedColorId,

    scrollPosition: state => state.scrollPosition
  }
}
