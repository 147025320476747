<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <ApplicationPopup/>
    <SuccessRegistrationModal v-if="isRegistered" />
  </div>
</template>

<script>
import MainLayout from '@/layouts/MainLayout'
import InnerLayout from '@/layouts/InnerLayout'
import SuccessRegistrationModal from '@/components/SuccessRegistrationModal'
import ApplicationPopup from '@/components/ApplicationPopup'

export default {
  components: {
    MainLayout, InnerLayout, SuccessRegistrationModal, ApplicationPopup
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || 'Inner') + 'Layout'
    },
    isRegistered() {
      return this.$store.getters.isRegistered
    },
  },

  async beforeCreate() {
    await this.$store.dispatch('getUserData')
    await this.$store.dispatch('getCategories')
    await this.$store.dispatch('getFooter')
    await this.$store.dispatch('getCart')
  }
}
</script>

<style lang="css">
@import '~bootstrap/dist/css/bootstrap.css';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~animate.css/animate.css';
@import 'assets/style.css';
</style>
