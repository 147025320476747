<template>
  <div class="gong-spinbutton">
    <p><b>כמות: </b></p>

    <div class="spin-button-component">
      <button class="spin-button" @click="increment">+</button>

      <input type="text" class="spin-input" v-model.number="amount" readonly>

      <button class="spin-button" @click="decrement">-</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpinButton',

  props: ['product'],

  computed: {
    errorQuantity() {
      return this.$store.getters.errorQuantity
    },
    amount: {
      get() {
        return parseInt(this.product.amount)
      },
      async set(newAmount) {
        const form = new FormData()
        form.append('prodID', this.product.id)
        form.append('amount_id', this.product.amount_id)
        form.append('sizeID', this.product.sizeID.id)
        form.append('colorID', this.product.colorID.id)
        form.append('amount', newAmount)

        await this.$store.dispatch('addToCart', {form})
      }
    }
  },

  methods: {
    async increment() {
      if(this.errorQuantity.prodID === this.product.amount_id) {
        return
      }
      this.amount++
    },

    async decrement() {
      if(this.amount > 1) {
        this.amount--
      }
    },
  },
}
</script>

<style scoped>
.gong-spinbutton{
  display: flex;
  direction: rtl;
}
#cart .gong-spinbutton p {
  display: none;
}
.spin-button {
  background-color: transparent;
  border: none;
  line-height: 1;
  font-family: 'Varela Round', sans-serif;
  margin: 0 .5rem;
  font-size: 1.5rem;
}
.spin-input {
  border: none;
  border-right: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  text-align: center;
  width: 47px;
}
.spin-button-component {
  display: flex;
}
</style>
