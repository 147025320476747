<template>
  <nav :class="['navbar', 'navbar-expand-lg', 'navbar-light', 'bg-light', { mainMenuFixed: fixedMenu }]" id="mainMenu" ref="mainMenu">
    <a href="/" class="navbar-brand" v-text="'GONG'" />

    <div id="navbarSupportedContent" @mouseleave="onMouseout">
      <ul class="navbar-nav" ref="navbarMenu">
        <router-link
          v-for="category of categories"
          :key="category.id"
          :to="{name: 'subCategories', params: {subCategoryId: category.id, fromMenu: true, scrollTop: true}}"
          tag="li"
          class="nav-item"
        >
          <a href="#" class="nav-link" @mouseover="setSubMenu(category, $event)" v-text="category.titleHEB" />
        </router-link>

        <li class="nav-item nav-search">
          <button class="btn-search" @click.prevent="openSearch" />
        </li>
      </ul>

      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeIn faster"
        leave-active-class="animated fadeOut faster"
      >
        <SubMenu v-if="showSubMenu" :category="category" />

        <SearchField v-if="showSearch" />
      </transition>
    </div>

    <ul class="list-unstyled list-inline cart-and-favorite">
      <li class="list-inline-item small-cart-container">
        <a href="#" @click.prevent="openSmallCart">
          <span class="icons icons-cart">
            <span class="quantityBag" v-text="getQuantityCart" />
          </span>
          <span class="icon-alt" v-text="'סל קניות'" />
        </a>
      </li>

      <li class="list-inline-item favorite-container">
        <router-link :to="{name: 'favorites'}">
          <span class="icons icons-favorite" />
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { eventEmitter } from '@/main'
import SubMenu from '@/components/menu/SubMenu'
import SearchField from '@/components/SearchField'

export default {
  name: 'MainMenu',

  components: {
    SubMenu, SearchField
  },

  data() {
    return {
      showSubMenu: false,
      subMenu: [],
      fixedMenu: false,
      showSearch: false,
      category: null
    }
  },

  watch: {
    $route() {
      this.showSubMenu = false
      this.showSearch = false
    }
  },

  computed: {
    categories() {
      return this.$store.getters.categories
    },
    getQuantityCart(){
      const cart = this.$store.state.cart.cart
      return cart.products ? cart.products.reduce((count, product) => count + parseInt(product.amount), 0) : 0
    }
  },

  methods: {
    setSubMenu(category, event) {
      if(!this.showSearch && category.subMenu.length > 0){
        this.showSubMenu = true
        this.category = category

        this.$refs.navbarMenu.childNodes.forEach(li => li.classList.remove('active'))
        event.target.parentElement.className += ' active'
      }
      else{
         this.showSubMenu = false
         this.$refs.navbarMenu.childNodes.forEach(li => li.classList.remove('active'))
      }
    },

    onMouseout() {
      this.showSubMenu = false
      this.$refs.navbarMenu.childNodes.forEach(li => li.classList.remove('active'))
    },

    openSearch() {
      this.showSearch = !this.showSearch
      this.showSubMenu = false
    },

    scrollHandle() {
      const windowScrollY = window.scrollY >= 140
      this.fixedMenu = windowScrollY
      eventEmitter.$emit('fixedMainMenu', windowScrollY)
    },

    openSmallCart() {
      eventEmitter.$emit('openSmallCart')
    },
  },

  created() {
    window.addEventListener('scroll', this.scrollHandle)

    eventEmitter.$on('closeSearch', () => this.showSearch = false)
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

header .navbar-light .navbar-nav .nav-link{
  color: #000;
  font-size: 1.2rem;
  text-align: right;
}
.navbar-nav{
  margin-right: auto;
  margin-left: auto;
}
header .navbar-light .router-link-active .nav-link{
  color: #ef383b;
}
header nav #navbarSupportedContent .navbar-nav {
  flex-direction: row-reverse;
}
header nav .navbar-nav li{
  margin-right: 25px;
}
header .navbar-light .navbar-nav li.active .nav-link{
  color: #ef383b;
}
header nav .navbar-nav li:first-child{
  margin-right: 0;
}
#navbarSupportedContent {
  display: flex !important;
  flex-direction: column;
  flex-basis: auto;
  flex-grow: 1;
  align-items: center;
  height: 3.5rem;
  position: relative;
}
.navbar-brand {
  display: none;
}
#app .mainMenuFixed{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  right: 0;
  background-color: #fff !important;
  border-bottom: 1px solid #e7e7e7;
  padding-top: .5rem !important;
  padding-bottom: .5rem;
  padding-right: 2.2rem;
  align-items: flex-start;
  transition: .1s;
  height: 3.5rem;
  flex-direction: row-reverse;
}
.cart-and-favorite{
  display: none;
  margin-bottom: 0;
  padding-top: .65rem;
  padding-left: 1.5rem;
}
.cart-and-favorite .icons-cart{
  width: 1.2rem;
  height: 1.1rem;
}
.quantityBag{
  position: absolute;
  text-align: center;
  font-size: .4rem;
  top: .36rem;
  color: #FFF;
  width: 1.15rem;
  height: 1.1rem;
}
.cart-and-favorite .icons-favorite{
  width: 1.1rem;
}
.cart-and-favorite .list-inline-item:not(:last-child){
  margin-right: 1.1rem;
}
.mainMenuFixed .cart-and-favorite{
  display: flex;
}
.mainMenuFixed .navbar-nav{
  padding-bottom: 0;
}
.mainMenuFixed .nav-item:last-child{
  display: none;
}
.mainMenuFixed .navbar-brand{
  display: block;
  line-height: 1;
  font-size: 1.9rem;
  margin-left: 2.2rem;
  margin-right: 0;
}
@include media-breakpoint-down(sm) {
  #mainMenu {
    display: none;
  }
}
</style>
