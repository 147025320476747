export default {
  computed: {
    allPrice() {
      return this.cart.products ? this.cart.products.reduce((count, product) => count + (parseInt(product.price) * parseInt(product.amount)), 0) : 0
    },

    allQuantity() {
      return this.cart.products ? this.cart.products.reduce((count, product) => count + parseInt(product.amount), 0) : 0
    }
  },

  methods: {
    async deleteFromCart() {
      const form = new FormData()
      form.append('id', this.product.id)
      form.append('sizeID', this.product.sizeID.id)
      form.append('colorID', this.product.colorID.id)

      await this.$store.dispatch('deleteFromCart', {form})
    },
  },
}
